<template>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12">
          <UploadField
            outlined
            v-if="documentation && localDocumentation"
            :value="documentation"
            @input="localDocumentation = $event"
            @change="dirty = true"
            :public="isPublic"
          ></UploadField>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import UploadField from "../../../fields/UploadField.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { UploadField },
  name: "SupportingDocumentationForm",
  props: {
    public: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({ valid: false, localDocumentation: { existing: [], deleted: [] } }),
  computed: {
    ...mapGetters(["documentation"]),
    isPublic() {
      return this.public;
    }
  },
  methods: {
    ...mapActions(["updateDocumentation"])
  },
  watch: {
    localDocumentation: {
      deep: true,
      immediate: false,
      handler(v) {
        this.updateDocumentation(v);
      }
    },
    documentation: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localDocumentation = v;
      }
    },

    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    }
  }
};
</script>
