<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="pr-0">
        <slot name="alert" />
        <h3 class="primary white--text pa-3" v-if="isShowHeader">Invoice Fields</h3>
        <InvoiceFieldsForm
          :participantEmail="participantEmail"
          :public="isPublic"
          @validation="$set(validations, 'invoiceFields', $event)"
        />

        <h3 class="primary white--text pa-3">Invoice Products</h3>
        <InvoiceProductsForm
          :askForPurchaseDate="askForPurchaseDate"
          :askForPurchasePrice="askForPurchasePrice"
          @validation="$set(validations, 'invoiceProducts', $event)"
        />

        <h3 class="primary white--text pa-3" v-if="hasTradeInProduct">
          Trade In? If items were "Traded In", please complete the section below.
        </h3>
        <InvoiceTradeInProductsForm
          v-if="hasTradeInProduct"
          @validation="$set(validations, 'tradeInProducts', $event)"
        />

        <h3 class="primary white--text pa-3">Additional Documentation</h3>
        <SupportingDocumentationForm
          @validation="$set(validations, 'supportingDocumentation', $event)"
          :public="isPublic"
        />

        <v-container class="text-center mb-5">
          <v-btn @click="$emit('previousStep')" text class="mr-2">
            {{ $i18n.translate("Back") }}
          </v-btn>
          <v-btn color="primary" class="mr-2" :disabled="!valid || hasAlert" @click="onNextStep">
            {{ $i18n.translate("Continue") }}</v-btn
          >
          <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
        </v-container>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="3" xl="3" class="grey lighten-3 pa-7">
        <template>
          <template v-if="mode == 'OCR'">
            <h3 class="pb-2"><v-icon color="primary">mdi-information-outline</v-icon> Invoice Import</h3>
            <p>
              Fields highlighted in <span style="background-color: #bbdefb">blue</span> were auto-filled based on
              information on the document uploaded.
            </p>
            <ul>
              <li class="pt-2 pb-2">
                Please confirm all fields and add or correct details.
              </li>
              <li class="pt-2 pb-2">
                Are all {{ $i18n.translate("Products") }} included in the invoice listed in this claim form?
              </li>
              <li class="pt-2 pb-2">
                To add more {{ $i18n.translate("Products") }}, select the model from the dropdown, then click the ADD
                button to add to your claim.
              </li>
              <template v-if="enableSerialNumbers">
                <li class="pt-2 pb-2">
                  For {{ $i18n.translate("Products") }} that require {{ $i18n.translate("Serial Numbers") }}, you will
                  need to enter the {{ $i18n.translate("Serial Number") }} for each item claimed.
                  <slot name="reminders"></slot>
                </li>
                <div class="row" v-if="showExampleImages">
                  <div class="col pt-2 pb-2">
                    <slot name="example-images"></slot>
                  </div>
                </div>
              </template>
            </ul>
          </template>
          <div
            v-if="
              (!isPublic &&
                this.selectedParticipant &&
                this.selectedParticipant.participantType &&
                this.selectedParticipant.participantType.allowClaimOnBehalfOf &&
                this.promotion &&
                this.promotion.promotionType &&
                this.promotion.promotionType.allowClaimingOnBehalf) ||
                this.$privilege.hasPrivilege('CLAIM_ON_BEHALF_OF_ANYONE')
            "
          >
            <v-divider class="mt-10 mb-10" />
            <h3 class="pb-2"><v-icon color="primary">mdi-account-multiple-outline</v-icon> Claim On-Behalf Of ...</h3>
            <p>
              You have the ability to claim on-behalf of another {{ $i18n.translate("Participant") }}. Select that
              {{ $i18n.translate("Participant") }} below:
            </p>
            <ParticipantField
              outlined
              :value="participantSelectedToClaimOnBehalf"
              :preventIds="[selectedParticipant.id]"
              :organization="!selectedParticipant.user.gappInternalUser ? selectedParticipant.organization : undefined"
              label="Select a Participant"
              :rules="rules.participant"
              @change="onChangeParticipantClaimOnBehalf"
              @input="updateParticipantSelectedToClaimOnBehalf($event)"
              :key="participantSelectedToClaimOnBehalf ? participantSelectedToClaimOnBehalf.id : 1"
              :onlyEffective="true"
              hide-details
            >
              <template v-slot:selectedItemDescription="{ item }">
                {{ item.fullName }} - {{ item.participantType ? item.participantType.name : "" }}
              </template>
              <template v-slot:itemDescription="{ item }">
                {{ item.fullName }} - {{ item.participantType ? item.participantType.name : "" }}
              </template>
            </ParticipantField>
          </div>

          <div v-if="!isPublic && endUserRelated">
            <v-divider class="mt-10 mb-10" />
            <h3 class="pb-2"><v-icon color="primary">mdi-account-arrow-left-outline</v-icon> Previous End Users</h3>
            <p>
              Save a little time by auto-filling a recently used End User. By choosing to save it, it will show up here
              on your next claim!
            </p>
            <v-checkbox
              v-if="endUserRelated && !selectedEndUser"
              :value="saveEndUserForLater"
              @change="updateSaveEndUserForLater($event)"
              label="Save end user on this form for later use."
            />
            <EndUserField
              hide-details
              outlined
              :participantId="
                participantSelectedToClaimOnBehalf ? participantSelectedToClaimOnBehalf.id : selectedParticipant.id
              "
              @input="loadFields()"
              @delete="onEndUserDelete($event)"
              @loaded="updateAvailableAddresses(addEndUserAddress($event))"
            />
          </div>

          <div v-if="availableAddresses && availableAddresses.length > 0">
            <v-divider class="mt-10 mb-10" />
            <h3 class="pb-2"><v-icon color="primary">mdi-map-marker-outline</v-icon> Addresses on Invoice</h3>
            <p>
              The following addresses were imported from your Invoice. Click on the address to auto-fill the form.
            </p>
            <v-list>
              <v-list-item v-for="address in availableAddresses" :key="address.fullAddress" link class="subitem-title">
                <v-list-item-icon class="mr-2 pr-0">
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle
                  style="white-space: normal !important;
                  overflow: visible !important;
                  text-overflow: clip !important;"
                  v-text="address.fullAddress"
                  @click="
                    loadAddress(address, true);
                    menuOpen = !menuOpen;
                  "
                />
              </v-list-item>
            </v-list>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoiceFieldsForm from "@/gapp-components/components/forms/claim/parts/InvoiceFieldsForm.vue";
import InvoiceProductsForm from "@/gapp-components/components/forms/claim/parts/InvoiceProductsForm.vue";
import InvoiceTradeInProductsForm from "@/gapp-components/components/forms/claim/parts/InvoiceTradeInProductsForm.vue";
import SupportingDocumentationForm from "@/gapp-components/components/forms/claim/parts/SupportingDocumentationForm.vue";
import ParticipantField from "@/gapp-components/components/fields/ParticipantField.vue";
import EndUserField from "@/gapp-components/components/fields/EndUserField.vue";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    EndUserField,
    ParticipantField,
    SupportingDocumentationForm,
    InvoiceProductsForm,
    InvoiceFieldsForm,
    InvoiceTradeInProductsForm
  },
  name: "ClaimCompleteInformationEntryStep",

  props: {
    public: {
      type: Boolean,
      default: false
    },
    showExampleImages: {
      type: Boolean,
      default: false
    },
    participantEmail: String,
    askForPurchasePrice: {
      type: Boolean,
      default: false
    },
    askForPurchaseDate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    claimFieldsByRow: [],
    uploads: {},
    enableSerialNumbers: false,
    productExamplesUploads: [],
    localClaimProducts: [],
    localClaimFieldValues: [],

    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Product",
        align: "left"
      },
      {
        value: "serialNumber",
        text: "Details",
        align: "left",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "right",
        sortable: false
      }
    ],
    addressToClaimFieldMappings: [
      {
        addressField: "address1",
        claimFieldTypeName: "END_USER_ADDRESS1_TYPE",
        convert: v => v
      },
      {
        addressField: "address2",
        claimFieldTypeName: "END_USER_ADDRESS2_TYPE",
        convert: v => v
      },
      {
        addressField: "city",
        claimFieldTypeName: "END_USER_CITY_TYPE",
        convert: v => v
      },
      {
        addressField: "region",
        claimFieldTypeName: "END_USER_REGION_TYPE",
        convert: v => v
      },
      {
        addressField: "country",
        claimFieldTypeName: "END_USER_COUNTRY_TYPE",
        convert: v => v
      },
      {
        addressField: "postalCode",
        claimFieldTypeName: "END_USER_POSTAL_CODE_TYPE",
        convert: v => v
      }
    ],
    selectedPromotionProducts: [],

    menuOpen: false,
    rules: {
      required: [v => !!v || "Field is required"],
      participant: [v => typeof v == "object" || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"]
    },
    validations: {}
  }),

  computed: {
    ...mapGetters([
      "selectedParticipant",
      "selectedClient",
      "claimFields",
      "claimFieldValues",
      "claimUploads",
      "claimProducts",
      "documentation",
      "participantSelectedToClaimOnBehalf",
      "selectedEndUser",
      "saveEndUserForLater",
      "availableAddresses",
      "promotion",
      "mode"
    ]),
    isShowHeader() {
      return this.claimFields.filter(claimField => !claimField.hidden).length > 0;
    },
    hasTradeInProduct() {
      if (this.promotion && this.promotion.promotionProducts) {
        return this.promotion.promotionProducts.find(pp => pp.product.productKey == "TRADE_IN_PRODUCT");
      }
      return null;
    },
    endUserRelated() {
      if (!this.claimFields) return false;
      let result = false;
      for (let field of this.claimFields) {
        if (field.claimFieldType.name.startsWith("END_USER")) {
          result = true;
        }
      }
      return result;
    },
    participantToClaim() {
      return this.participantSelectedToClaimOnBehalf && this.participantSelectedToClaimOnBehalf.id
        ? this.participantSelectedToClaimOnBehalf
        : this.selectedParticipant;
    },
    valid() {
      for (let property of Object.getOwnPropertyNames(this.validations)) {
        if (this.validations[property] == false) {
          return false;
        }
      }
      return true;
    },
    isPublic() {
      return this.public;
    },
    hasAlert() {
      return this.$slots.alert?.length > 0;
    }
  },
  watch: {
    localClaimProducts: {
      deep: true,
      handler(v) {
        this.updateClaimProducts(v);
      }
    },
    claimProducts: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimProducts = v;
      }
    },
    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    },
    localClaimFieldValues: {
      deep: true,
      immediate: true,
      handler(v) {
        this.updateClaimFieldValues(v);
      }
    }
  },
  created() {
    this.$root.$on("ClaimCompleteInformationEntryStep-validate", () => {
      this.$root.$emit("InvoiceProductsForm-validate");
      this.$root.$emit("InvoiceFieldsForm-validate");
    });

    this.promotion.promotionProducts.forEach(promotionProduct => {
      if (promotionProduct.enableSerialNumbers) {
        this.enableSerialNumbers = promotionProduct.enableSerialNumbers;
      }
    });
    this.$set(this.localClaimFieldValues);
  },
  methods: {
    ...mapActions([
      "updateAvailableAddresses",
      "updateClaimFields",
      "updateClaimFieldValues",
      "updateClaimProducts",
      "updateDocumentation",
      "updateParticipantSelectedToClaimOnBehalf",
      "updateSelectedEndUser",
      "updateSaveEndUserForLater"
    ]),

    onNextStep() {
      this.$emit("nextStep");
    },

    onChangeParticipantClaimOnBehalf() {
      Promise.all([this.fetchPreferredAwardVehicle(), this.fetchAvailableAwardVehicles()]);
    },

    fetchPreferredAwardVehicle() {
      this.$api
        .get(`/api/participants/${this.participantToClaim.id}/preferredAwardVehicle`)
        .then(({ data }) => {
          this.preferredAwardVehicle = data;
        })
        .catch(() => {
          this.preferredAwardVehicle = undefined;
        });
    },

    fetchAvailableAwardVehicles() {
      return this.$awardvehicle
        .getAvailableAwardVehicles(this.participantToClaim, this.promotion, this.isPublic)
        .then(avs => {
          this.availableAwardVehicles = avs;
        });
    },

    onCancel() {
      this.$emit("cancel");
    },

    loadFields(v) {
      this.selectedEndUser = v;
      if (v) {
        for (let claimField of this.claimFields) {
          let claimFieldId = claimField.id;
          switch (claimField.claimFieldType.name) {
            case "END_USER_POSTAL_CODE_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address.postalCode);
              break;
            case "END_USER_FIRST_NAME_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.firstName);
              break;
            case "END_USER_LAST_NAME_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.lastName);
              break;
            case "END_USER_ORGANIZATION_NAME_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.organizationName);
              break;
            case "END_USER_ADDRESS1_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address ? v.address.address1 : null);
              break;
            case "END_USER_ADDRESS2_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address ? v.address.address2 : null);
              break;
            case "END_USER_CITY_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address ? v.address.city : null);
              break;
            case "END_USER_REGION_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address ? v.address.region : null);
              break;
            case "END_USER_COUNTRY_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.address ? v.address.country : null);
              break;
            case "END_USER_EMAIL_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.email);
              break;
            case "END_USER_PHONE_NUMBER1_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.phoneNumber1);
              break;
            case "END_USER_PHONE_NUMBER2_TYPE":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.phoneNumber2);
              break;
            case "END_USER_INDUSTRY":
              Vue.set(this.localClaimFieldValues, claimFieldId, v.industry);
              break;
          }
        }
        this.$forceUpdate();
      }
    },

    onEndUserDelete(v) {
      if (v.id == this.selectedEndUser.id) {
        this.selectedEndUser = null;
      }
    },

    loadAddress(address, initialLoad = false) {
      this.addressToClaimFieldMappings.forEach(mapping => {
        let claimField = this.findClaimFieldByClaimFieldType(this.claimFields, mapping.claimFieldTypeName);
        if (claimField?.id) {
          this.$set(this.localClaimFieldValues, claimField.id, mapping.convert(address[mapping.addressField]));
          if (initialLoad) {
            claimField.ocrPopulated = true;
          }
        }
      });
      this.$forceUpdate();
    },

    findClaimFieldByClaimFieldType(claimFields, claimFieldTypeName) {
      return claimFields.find(cf => cf.claimFieldType.name === claimFieldTypeName) || null;
    },

    addEndUserAddress(e) {
      return this.availableAddresses.concat(e.map(endUser => endUser.address));
    }
  }
};
</script>
