var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"text-center",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Product Registration"))+" ")]),_c('v-card-text',[_c('v-form',{ref:"mainForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.productsToRegisterHeaders,"items":_vm.localProductsToRegister,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.promotionProduct.product.productKey",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"productKeyField"},[_vm._v(_vm._s(item.promotionProduct.product.productKey))])])],1)]}},{key:"item.serialNumber",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":12,"xl":6}},[(item.promotionProduct.enableSerialNumbers)?_c('v-text-field',{class:item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2',attrs:{"prefix":"S01 -","outlined":"","dense":"","background-color":item.ocrPopulated ? '#bbdefb' : null,"label":_vm.$i18n.translate('Serial Number'),"rules":item.promotionProduct.enableSerialNumbers
                        ? [_vm.requireSerialNumber(item), _vm.checkDuplicatedSerialNumber(item)]
                        : [],"disabled":!item.promotionProduct.enableSerialNumbers,"readonly":!item.promotionProduct.enableSerialNumbers},on:{"keydown":function($event){return _vm.keyHandler($event)},"input":function($event){item.ocrPopulated = false},"blur":function($event){return _vm.formatSerialNumber(item)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.flags.includeClaimProductsSerialNumberTooltip)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function () {}}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.translate(_vm.strings.claimProductsSerialnumberTooltipText))+" ")])]):_vm._e()]},proxy:true}],null,true),model:{value:(item.serialNumber),callback:function ($$v) {_vm.$set(item, "serialNumber", $$v)},expression:"item.serialNumber"}}):_c('v-chip',{staticClass:"mt-3",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.$i18n.translate("Serial Number"))+" : N/A ")])],1)],1)]}},{key:"item.purchaseDate",fn:function(ref){
                        var item = ref.item;
return [_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":12}},[_c('DateTimePickerField',{attrs:{"label":_vm.$i18n.translate('Purchase Date'),"rules":_vm.rules.claimProductsPurchaseDate
                        ? _vm.rules.claimProductsPurchaseDate
                        : [function (v) { return !!v || _vm.$i18n.translate('Purchase Date is required'); }],"required":true,"format":"MM/DD/YYYY","defaultTime":"00:00","onlyDate":""},model:{value:(item.purchaseDate),callback:function ($$v) {_vm.$set(item, "purchaseDate", $$v)},expression:"item.purchaseDate"}})],1)],1)]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDeleteProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Delete Product"))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDuplicateProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-content-duplicate")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.translate("Duplicate Product")))])])]}}])}),_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-autocomplete',{ref:"menu",staticClass:"required mb-5",attrs:{"dense":"","outlined":"","items":_vm.promotion && _vm.promotion.promotionProducts ? _vm.promotion.promotionProducts : [],"label":_vm.$i18n.translate('add a Product'),"item-value":"id","item-text":function (item) {
                    return item && item.product ? item.product.productKey : '';
                  },"multiple":"","return-object":"","rules":[_vm.requireProduct],"hint":_vm.$i18n.translate('You must select at least 1 product for this claim'),"attach":"","auto":"","search-input":_vm.searchInput,"menu-props":{ zIndex: '9999' }},on:{"input":function($event){_vm.searchInput = null},"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-btn',{staticClass:"primary mt-n2",on:{"click":function($event){_vm.onAddProduct();
                        _vm.closeMenu();}}},[_vm._v("Add")])],1)]},proxy:true}]),model:{value:(_vm.selectedProductsToRegister),callback:function ($$v) {_vm.selectedProductsToRegister=$$v},expression:"selectedProductsToRegister"}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('previousStep')}}},[_vm._v(_vm._s(_vm.$i18n.translate("Back")))]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('nextStep')}}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }