<template>
  <v-card v-if="awardVehicle" :dark="selected" v-bind="$attrs" v-on="$listeners" :loading="loading">
    <v-card-title style="word-break: keep-all;" class="headline no-wrap-selection mb-15">
      <span v-if="showTitle">{{ awardVehicle.name }}</span>
      <span v-else>&nbsp;</span>
    </v-card-title>
    <v-card-text style="word-break: keep-all;" class="flex no-wrap-selection" v-if="!loading">
      <v-btn
        class="mb-5"
        v-if="!readOnly"
        @click="onSelect"
        :disabled="
          selected ||
            disabled ||
            (requiresBankAccount && !bankAccount) ||
            !awardVehicleMeetsMinimumClaimAmount ||
            !awardVehicleMeetsMaximumClaimAmount
        "
      >
        {{ selected ? buttonSelectedText : buttonSelectText }}
      </v-btn>

      <p v-if="!awardVehicleMeetsMinimumClaimAmount" style="color: red" class="no-wrap">
        There is a minimum of
        {{ awardVehicle.minimumPayoutAmount | toNumber(2, selectedProgram) }}
        {{ awardVehicle.currency.name }}
      </p>

      <p v-if="!awardVehicleMeetsMaximumClaimAmount" style="color: red" class="no-wrap">
        There is a maximum of
        {{ awardVehicle.maximumPayoutAmount | toNumber(2, selectedProgram) }}
        {{ awardVehicle.currency.name }}
      </p>

      <div v-if="awardVehicle.awardVehicleType.name == 'BLACKHAWK_NETWORK'">
        <p v-if="showDescription">Payout as Prepaid Debit Card</p>
        <span v-if="selectedParticipant && selectedParticipant.address">
          <b>Upon earning, Card will be mailed to:<br /></b>
          <span v-if="selectedParticipant && selectedParticipant.address">
            <span>{{ selectedParticipant.fullName }}<br /></span>
            <span>{{ selectedParticipant.address.fullAddress }}</span>
          </span>
        </span>

        <span v-else-if="strings.prepaidCardsMessage">
          {{ strings.prepaidCardsMessage }}
        </span>
        <span v-else>
          No Address on file. Please update your profile.
        </span>

        <span v-if="showBHNCanadianRegulationPayoutInfo" style="color: red" class="no-wrap">
          <br />
          Per Canadian compliance and regulations, your payout may be split into multiple single load prepaid cards.
        </span>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'CHECKISSUING_ACH'">
        <p v-if="showDescription">Payout as ACH Direct Deposit</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'CHECKISSUING_CHECK'">
        <p v-if="showDescription">Payout as Check</p>
        <p v-if="checkissuingAccount">
          <span>Payable To: {{ checkissuingAccount.mailToName }}<br /></span>
          <span v-if="checkissuingAccount.address">
            Mail To:
            <span>{{ checkissuingAccount.address.fullAddress }}</span>
          </span>
          <span v-else>
            No Address on file. Please update your profile.
          </span>
        </p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'COUPON_CODE'">
        <p v-if="showDescription">Payout as Coupon Code</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'CREDIT_MEMO'">
        <p v-if="showDescription">Payout as Credit Memo</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'PREPAID_TECHNOLOGIES_CARD'">
        <p v-if="showDescription">Payout as Prepaid Debit Card</p>
        <v-list two-line v-if="prepaidTechnologiesCards && prepaidTechnologiesCards.length > 0">
          <v-list-item-group>
            <template v-for="card in prepaidTechnologiesCards">
              <v-list-item :key="card.id" :ripple="false" :selectable="false">
                <v-list-item-content>
                  <v-list-item-title>{{ selectedParticipant.fullName }}</v-list-item-title>
                  <v-list-item-subtitle>**** **** **** {{ card.cardNumberLastFour }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text>Balance</v-list-item-action-text>
                  <span v-if="card.balance">
                    {{ card.balance | toCurrency }}
                  </span>
                  <span v-else-if="card.loading">
                    <v-progress-circular :size="20" indeterminate color="primary"></v-progress-circular>
                  </span>
                  <span v-else>
                    TBD
                  </span>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <p v-else>
          <span v-if="selectedParticipant && selectedParticipant.address">
            <b>Upon earning, Card will be mailed to:<br /></b>
            <span v-if="selectedParticipant.address">
              <span>{{ selectedParticipant.fullName }}<br /></span>
              <span>{{ selectedParticipant.address.fullAddress }}</span>
            </span>
          </span>
          <span v-else-if="customAddress">
            <span>{{ customAddress.fullAddress }}</span>
          </span>
          <span v-else-if="strings.prepaidCardsMessage">
            {{ strings.prepaidCardsMessage }}
          </span>
        </p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'STORE_PRODUCT'">
        <p v-if="showDescription">Payout as Store Product</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'VIRTUAL_FUNDS'">
        <p v-if="showDescription">Payout as Virtual Funds</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'VOPAY_ACH'">
        <p v-if="showDescription">Payout as ACH Direct Deposit</p>
      </div>
      <div v-else-if="awardVehicle.awardVehicleType.name == 'VOPAY_EFT'">
        <p v-if="showDescription">Payout as EFT Direct Deposit</p>
      </div>
      <div v-else>
        <p v-if="showDescription">{{ awardVehicle.description }}</p>
      </div>

      <div v-if="requiresBankAccount">
        <BankAccountField
          label="Primary Bank Account"
          class="required"
          v-model="bankAccount"
          :hint="bankAccount ? null : 'No primary bank account setup, add one here'"
          :persistent-hint="bankAccount ? false : true"
          @bankAccount="onBankAccount"
          :participant="selectedParticipant"
          :clearable="false"
          :isPublic="isPublic"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import BankAccountField from "../../fields/BankAccountField";

export default {
  name: "AwardVehicleCard",
  components: {
    BankAccountField
  },
  props: {
    awardVehicle: Object,
    selected: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    public: {
      type: Boolean,
      default: false
    },
    showTitle: { type: Boolean, default: true },
    showDescription: { type: Boolean, default: true },
    buttonSelectText: { type: String, default: "Select" },
    buttonSelectedText: { type: String, default: "Selected" },
    primaryBankAccount: Object,
    claimAmount: Number,
    lookupPrimaryBankAccount: { type: Boolean, default: false },
    customAddress: { type: Object }
  },
  data() {
    return {
      loading: false,
      checkissuingAccount: null,
      prepaidTechnologiesCards: null,
      bankAccount: null
    };
  },
  methods: {
    onSelect() {
      this.$emit("selected", this.awardVehicle);
    },
    onBankAccount(ba) {
      this.bankAccount = ba;
      this.$emit("bankAccount", ba);
    },
    fetchPrimaryBankAccount() {
      if (this.lookupPrimaryBankAccount === true) {
        return this.$api
          .post("/api/bankAccounts/search?size=1", { isEffective: "Active", primary: true })
          .then(({ data }) => {
            if (data.content && data.content.length == 1) {
              this.bankAccount = data.content[0];
            } else {
              this.bankAccount = null;
            }
            return this.bankAccount;
          });
      } else {
        this.bankAccount = this.primaryBankAccount;
        return Promise.resolve(this.bankAccount);
      }
    },
    fetchCheckissuingAccount() {
      if (this.awardVehicle.awardVehicleType.name == "CHECKISSUING_CHECK") {
        return this.$api
          .get("/api/checkissuingPrograms/byAwardVehicle/" + this.awardVehicle.id + "/checkissuingAccount")
          .then(({ data }) => {
            this.checkissuingAccount = data;
          })
          .catch(() => {
            console.log("No Checkissuing account found");
          })
          .finally(() => {
            this.isBusy = false;
          });
      } else {
        return Promise.resolve();
      }
    },
    fetchPrepaidTechnologiesCards() {
      if (this.awardVehicle.awardVehicleType.name == "PREPAID_TECHNOLOGIES_CARD") {
        return this.$api
          .post("/api/prepaidTechnologiesCards/awardVehicle/" + this.awardVehicle.id + "/search", {})
          .then(({ data }) => {
            this.prepaidTechnologiesCards = data.content;
            return this.prepaidTechnologiesCards.reduce(
              (p, card) => this.fetchPrepaidTechnologiesCardBalance(card),
              Promise.resolve()
            );
          });
      } else {
        return Promise.resolve();
      }
    },
    fetchPrepaidTechnologiesCardBalance(card) {
      Vue.set(card, "loading", true);
      return this.$api
        .post(
          "/api/prepaidTechnologiesCards/awardVehicle/" + this.awardVehicle.id + "/card/" + card.id + "/balance",
          {}
        )
        .then(({ data }) => {
          Vue.set(card, "balance", data);
        })
        .finally(() => {
          Vue.set(card, "loading", false);
        });
    }
  },
  mounted() {
    if (
      this.selected === true &&
      (!this.awardVehicleMeetsMinimumClaimAmount || !this.awardVehicleMeetsMaximumClaimAmount)
    ) {
      console.log(
        "AwardVehicleCard: " +
          this.awardVehicle.awardVehicleKey +
          " " +
          this.awardVehicle.name +
          " is selected but doesn't meet min or max, attempting to emit selected = false"
      );
      this.$emit("unselected", null);
    }
    if (!this.isPublic) {
      this.loading = true;
      Promise.all([
        this.fetchPrimaryBankAccount(),
        this.fetchCheckissuingAccount(),
        this.fetchPrepaidTechnologiesCards()
      ]).finally(() => {
        this.loading = false;
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedLocale", "strings"]),
    isPublic() {
      return this.public;
    },
    requiresBankAccount() {
      return (
        this.awardVehicle &&
        this.awardVehicle.awardVehicleType &&
        this.awardVehicle.awardVehicleType.bankAccountRequired
      );
    },
    awardVehicleMeetsMinimumClaimAmount() {
      if (
        this.claimAmount &&
        this.awardVehicle.minimumPayoutAmount &&
        this.claimAmount < this.awardVehicle.minimumPayoutAmount
      ) {
        return false;
      } else {
        return true;
      }
    },
    awardVehicleMeetsMaximumClaimAmount() {
      if (!this.claimAmount) {
        return true;
      }

      if (!this.awardVehicle.maximumPayoutAmount) {
        return true;
      }

      if (
        this.claimAmount > this.awardVehicle.maximumPayoutAmount &&
        !this.awardVehicle.splitPayoutAmountBasedOnMaximum
      ) {
        return false;
      }

      return true;
    },

    showBHNCanadianRegulationPayoutInfo() {
      if (this.selectedParticipant) {
        return this.selectedParticipant.address.country.name === "CAN" && this.claimAmount && this.claimAmount > 999;
      } else {
        return this.selectedLocale.countryType.name === "CAN" && this.claimAmount && this.claimAmount > 999;
      }
    }
  }
};
</script>
<style>
.no-wrap-selection,
p,
span {
  word-break: keep-all !important;
}
</style>
