<template>
  <v-container>
    <h3>Claim Submitted Successfully</h3>
    <br />
    <v-row>
      <v-col cols="12">
        Your claim has been received and currently being processed. <br /><br />Claim Number:
        <b>{{ claim ? claim.claimKey : "" }}</b
        ><br /><br />

        <v-btn v-if="!isPublic" @click="$emit('backToDashboard')">Back to Dashboard</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SuccessStep",
  computed: {
    ...mapGetters(["claim"]),
    isPublic() {
      return this.public;
    }
  },
  props: {
    public: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
