<template
  ><v-card-text>
    <v-form ref="uploadForm" @submit.prevent="$emit('nextStep')" v-if="!isBusy" v-model="valid.step3">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>Submit Invoice Backup</h3>
            <ul>
              <li>
                Please utilize the Upload documents tool below to attach copies of the required supporting documentation
                for your claim.
              </li>
              <li>Up to 12 documents for activity verification are allowed.</li>
              <li>
                Refer to the Promotion for proper documentation requirements and all Terms and Conditions.
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <UploadField v-model="claimUploads" :rules="[requireUpload]"></UploadField>
          </v-col>
        </v-row>
      </v-container>
      <v-btn @click="$emit('previousStep')" text class="mr-2">{{ $i18n.translate("Back") }}</v-btn>
      <v-btn color="primary" :disabled="!valid.step3" @click="$emit('nextStep')">
        Continue
      </v-btn>
      <v-btn @click="onCancel" text>{{ $i18n.translate("Cancel") }}</v-btn>
    </v-form>
  </v-card-text>
</template>

<script>
import UploadField from "@/gapp-components/components/fields/UploadField.vue";

export default {
  name: "SupportingDocumentationStep",
  components: { UploadField },
  data: () => ({
    claimUploads: [],
    uploads: {},
    valid: {
      step3: false
    }
  }),
  watch: {
    claimUploads: {
      deep: true,
      immediate: true,
      handler(v) {
        this.$emit("input", {
          claimUploads: v
        });
      }
    }
  }
};
</script>

<style></style>
