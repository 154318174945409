<template>
  <div :style="getStylesForContent('component')">
    <v-card v-if="!isLoaded">
      <v-card-title class="headline" />
      <v-card-text>
        <div class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="isLoaded && blockScreen">
      <v-card-title class="headline">
        {{ blockScreen.title }}
      </v-card-title>
      <v-card-text>
        {{ blockScreen.message }}
      </v-card-text>
    </v-card>
    <v-card :loading="isBusy" v-if="isLoaded && !blockScreen">
      <v-card-title class="headline">
        <template v-if="promotion">
          Claim - {{ promotion.name }}
          <a @click="showUploadedFilesDialog = true" style="margin-left: 5px;"> ({{ promotion.promotionKey }}) </a>
        </template>
        <template v-else>
          Claim
        </template>
      </v-card-title>
      <v-dialog v-model="showUploadedFilesDialog" max-width="800" persistent>
        <v-card>
          <v-card-title>Files</v-card-title>
          <v-card-text>
            <v-container class="ma-0 pa-0">
              <v-row dense>
                <v-col cols="1" dense />
                <v-col cols="11" dense>
                  <ol>
                    <li v-for="(upload, i) in claimUploads" :key="i">
                      <a :href="upload.href" target="_blank">
                        {{ upload.originalFilename }}
                      </a>
                    </li>
                  </ol>
                </v-col>
              </v-row>
              <v-row class="text-right" dense>
                <v-col cols="12" dense>
                  <v-btn class="primary" @click="showUploadedFilesDialog = false">Close</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card-text>
        <ApiError :errors="errors" />
        <span
          v-if="errors && errors.message && strings && strings.errorsAdditionalMessage"
          v-html="strings.errorsAdditionalMessage"
        />
        <v-stepper
          v-model="currentStep"
          alt-labels
          v-if="currentStep"
          :bind="getPropsForContent('mainStepper')"
          :style="getStylesForContent('mainStepper')"
          id="mainStepper"
        >
          <v-stepper-header
            v-if="steps"
            :bind="getPropsForContent('mainStepperHeader')"
            :style="getStylesForContent('mainStepperHeader')"
          >
            <template v-for="(step, index) in steps.filter(step => step.showInHeader)">
              <v-stepper-step
                :step="index + 1"
                :key="index + 1"
                :editable="(index == 0 || valid[index - 1]) && !isClaimSubmitted"
              >
                {{ step.name }}
              </v-stepper-step>
              <v-divider
                v-if="index !== steps.filter(step => step.showInHeader).length - 1"
                :key="'divider' + index + 1"
              />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content v-for="(step, index) in steps" :key="index + 1" :step="index + 1">
              <AwardVehicleSelectionStep
                v-if="step.stepKey == 'AWARD_VEHICLE_SELECTION'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @submit="onSubmit"
                @previousStep="onPreviousStep"
                :public="isPublic"
                :publicParticipantTypeKey="publicParticipantTypeKey"
                :active="steps[currentStep - 1].stepKey == 'AWARD_VEHICLE_SELECTION'"
              >
                <template v-slot:payout-instructions="slotProps">
                  <slot name="payout-instructions" v-bind="slotProps" />
                </template>
              </AwardVehicleSelectionStep>
              <ClaimDetailsStep
                v-if="step.stepKey == 'CLAIM_DETAILS'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @submit="onSubmit"
                @previousStep="onPreviousStep"
                @updateOptIn="updateOptIn($event)"
                :isOptInRequired="isOptInRequired"
                :showTermsAndConditions="showTermsAndConditions"
              >
                <template v-slot:askForSubscriptionSlot="slotProps">
                  <slot name="askForSubscriptionSlot" v-bind="slotProps" />
                </template>
                <slot name="showTermsAndConditionsSlot" />
              </ClaimDetailsStep>
              <ClaimCompleteInformationEntryStep
                v-if="step.stepKey == 'CLAIM_COMPLETE_INFORMATION_ENTRY'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @previousStep="onPreviousStep"
                :claim="claim"
                :public="isPublic"
                :participantEmail="participantEmail"
                :askForPurchaseDate="askForPurchaseDate"
                :askForPurchasePrice="askForPurchasePrice"
                :showExampleImages="showExampleImages"
              >
                <template v-slot:reminders v-if="reminders">
                  <div v-for="reminder in reminders" :key="reminder.id" class="pt-2">
                    <strong v-if="reminder.text">Reminder:</strong> {{ reminder.text }}
                  </div>
                </template>
                <template v-slot:example-images v-if="showExampleImages">
                  <slot name="example-images" />
                </template>
                <template v-slot:alert>
                  <v-alert v-if="showCountryMismatchWarning" type="warning" border="top" dense>
                    {{ countryMismatchWarningMessage }}
                  </v-alert>
                </template>
              </ClaimCompleteInformationEntryStep>
              <OcrStep
                v-if="step.stepKey == 'OCR_INVOICE_UPLOAD'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @previousStep="onPreviousStep"
                :promotion="promotion"
                :public="isPublic"
              >
                <template v-slot:alert>
                  <v-alert v-if="showCountryMismatchWarning" type="warning" border="top" dense>
                    {{ countryMismatchWarningMessage }}
                  </v-alert>
                </template>
              </OcrStep>
              <SuccessStep
                v-if="step.stepKey == 'SUCCESS'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @backToDashboard="backToDashboard"
                @previousStep="onPreviousStep"
                :promotion="promotion"
                :public="isPublic"
              />
              <SupportingDocumentationStep
                v-if="step.stepKey == 'SUPPORTING_DOCUMENTATION'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @previousStep="onPreviousStep"
                :promotion="promotion"
              />
              <ZeroSalesStep
                v-if="step.stepKey == 'ZERO_SALES_ENTRY'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                :promotion="promotion"
              />
              <ProductRegistrationStep
                v-if="step.stepKey == 'PRODUCT_REGISTRATION'"
                @validation="$set(valid, index, $event)"
                @nextStep="onNextStep"
                @cancel="onCancel"
                @backToDashboard="backToDashboard"
                @previousStep="onPreviousStep"
                :promotion="promotion"
                :public="isPublic"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import * as promotionFetching from "@/gapp-components/components/forms/claim/promotionFetching.js";
import AwardVehicleSelectionStep from "@/gapp-components/components/forms/claim/steps/AwardVehicleSelectionStep.vue";
import ClaimCompleteInformationEntryStep from "@/gapp-components/components/forms/claim/steps/ClaimCompleteInformationEntryStep.vue";
import ClaimDetailsStep from "@/gapp-components/components/forms/claim/steps/ClaimDetailsStep.vue";
import OcrStep from "@/gapp-components/components/forms/claim/steps/OcrStep.vue";
import ProductRegistrationStep from "@/gapp-components/components/forms/claim/steps/ProductRegistrationStep.vue";
import SuccessStep from "@/gapp-components/components/forms/claim/steps/SuccessStep.vue";
import SupportingDocumentationStep from "@/gapp-components/components/forms/claim/steps/SupportingDocumentationStep.vue";
import ZeroSalesStep from "@/gapp-components/components/forms/claim/steps/ZeroSalesStep.vue";
import * as stepsBuilder from "@/gapp-components/components/forms/claim/stepsBuilder.js";
import { RESET_CLAIM_STATE } from "@/gapp-components/store/claim.module";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ApiError,
    AwardVehicleSelectionStep,
    ClaimDetailsStep,
    ClaimCompleteInformationEntryStep,
    OcrStep,
    SuccessStep,
    SupportingDocumentationStep,
    ProductRegistrationStep,
    ZeroSalesStep
  },
  name: "ClaimForm",
  props: {
    promotionId: [Number, String],
    promotionKey: String,
    claimId: Number,
    enableOcr: { type: Boolean, default: false },
    showExampleImages: { type: Boolean, default: false },
    serialNumberConversion: Function,
    public: {
      type: Boolean,
      default: false
    },
    reminders: [],
    payoutInstructions: [],
    publicParticipantTypeKey: {
      type: String
    },
    isOptInRequired: { type: Boolean, default: false, required: false },
    showTermsAndConditions: { type: Boolean, default: false, required: false },
    askForPurchaseDate: {
      type: Boolean,
      default: false
    },
    askForPurchasePrice: {
      type: Boolean,
      default: false
    },
    contentConfig: {
      type: Object,
      default: () => {
        return {};
      }
    },
    strings: {
      type: Object,
      default: () => {
        return {};
      }
    },
    objects: {
      type: Object,
      default: () => {
        return {};
      }
    },
    flags: {
      type: Object,
      default: () => {
        return {};
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    showUploadedFilesDialog: false,
    isClaimSubmitted: false,
    isLoaded: false,
    hasInvalidKey: false,
    showCountryMismatchWarning: false,
    cards: [],
    steps: [],
    valid: [],
    localProductsToRegister: [],
    errors: {},
    promotion: null,
    claimStage: null,
    promotionSkipClaimDocumentation: null,
    currentStep: null,
    participantEmail: null,
    claim: null
  }),
  methods: {
    ...mapActions([
      "updateAvailableAddresses",
      "updateClaimFieldValues",
      "updateClaimFieldValue",
      "updateClaimFields",
      "updateClaimProducts",
      "updateProductsToRegister",
      "updateClaimUploads",
      "updateDocumentation",
      "updateFormattedZeroSalesPeriod",
      "updateParticipantSelectedToClaimOnBehalf",
      "updateSaveEndUserForLater",
      "updateSelectedEndUser",
      "updateSelectedZeroSalesPeriod",
      "updateZeroSalesItems",
      "updateMode",
      "updatePromotion",
      "updateClaimId",
      "resetClaimState",
      "updateClaim",
      "updateIsPublic",
      "updateIsBusy",
      "updateOptIn",
      "updateStrings",
      "updateObjects",
      "updateFlags",
      "updateRules"
    ]),
    getPropsForContent(contentId) {
      if (contentId && this.contentConfig && this.contentConfig[contentId] && this.contentConfig[contentId].props) {
        return this.contentConfig[contentId].props;
      }
      return {};
    },
    getStylesForContent(contentId) {
      if (contentId && this.contentConfig && this.contentConfig[contentId] && this.contentConfig[contentId].styles) {
        return this.contentConfig[contentId].styles;
      }
      return {};
    },
    async fetchData() {
      this.updateIsBusy(true);
      if (this.promotionKey && this.public) {
        let promotionInfo = await promotionFetching.fetchPublicPromotionByKey(this.promotionKey);
        this.promotion = promotionInfo.promotion;
        this.promotion.program = { id: promotionInfo.programId };
        this.promotion.promotionProducts = promotionInfo.promotionProducts;
        this.promotion.promotionDeadlines = promotionInfo.promotionDeadlines;
        this.promotion.promotionProducts = promotionInfo.promotionProducts;
        this.updateClaimFields(promotionInfo.claimFields);
        this.updateClaimUploads(promotionInfo.uploads);
        this;
      } else {
        if (this.public) {
          let promotionInfo = await promotionFetching.fetchPublicPromotionById(this.promotionId);

          this.promotion = promotionInfo.promotion;
          this.promotion.program = { id: promotionInfo.programId };
          this.promotion.promotionProducts = promotionInfo.promotionProducts;
          this.promotion.promotionDeadlines = promotionInfo.promotionDeadlines;
          this.promotion.promotionProducts = promotionInfo.promotionProducts;
          this.updateClaimFields(promotionInfo.claimFields);
          this.updateClaimUploads(promotionInfo.uploads);
        } else {
          this.promotion = await promotionFetching.fetchPromotionById(this.promotionId);
        }
      }

      const localeCountry = this.selectedLocale?.countryType?.localeCountry;
      const promotionCountry = this.promotion?.country?.localeCountry;
      this.showCountryMismatchWarning = localeCountry !== promotionCountry;

      this.updatePromotion(this.promotion);
      if (!this.public) {
        await this.fetchFirstClaimStageForClaimType(this.promotion);
        let claimFields = await this.fetchClaimFieldsForPromotion(this.promotion);
        this.updateClaimFields(claimFields);
      }

      if (this.claimId) {
        await this.fetchClaimData(this.claimId);
      }

      this.breadcrumb();
      this.updateIsBusy(false);
    },

    fetchFirstClaimStageForClaimType(claimType) {
      return this.$api
        .get("/api/claimStages/byClaimTypeId/" + claimType.id + "?sort=rankOrder,ASC&size=1")
        .then(({ data }) => {
          this.claimStage = data.content[0];
        });
    },

    validateAllForms() {
      this.$root.$emit("ClaimCompleteInformationEntryStep-validate"); // check later
    },

    findClaimFieldByClaimFieldType(claimFields, claimFieldTypeName) {
      let returnValue = null;
      claimFields.forEach(cf => {
        if (cf.claimFieldType.name == claimFieldTypeName) {
          returnValue = cf;
        }
      });
      return returnValue;
    },

    processOcrDocumentQueryResult(ocrResult, upload) {
      if (ocrResult.status == "READY" && this.ocrAttempts < this.ocrMaxAttempts) {
        let claimField;
        for (let answer in ocrResult.answers) {
          let answersList = ocrResult.answers[answer];
          let queryMapping = this.ocrDocumentQueryToClaimFieldMappings.find(elem => elem.query == answer);
          if (queryMapping) {
            claimField = this.findClaimFieldByClaimFieldType(queryMapping.claimFieldTypeName);
            if (claimField) {
              this.$set(this.claimFieldValues, claimField.id, queryMapping.convert(answersList[0]));
              this.$set(claimField, "ocrPopulated", true);
            }
          }
        }
        this.loadingOcrDocumentQuery = false;
      } else if (ocrResult.status == "PENDING" && this.ocrAttempts < this.ocrMaxAttempts) {
        this.ocrAttempts++;
        setTimeout(() => {
          this.$api.post("/api/ocr/checkDocumentQuery/" + this.promotion.id + "/" + upload.id).then(({ data }) => {
            this.processOcrDocumentQueryResult(data, upload);
          });
        }, 5000);
      } else if (this.ocrAttempts == this.ocrMaxAttempts) {
        this.loadingOcrDocumentQuery = false;
      }

      this.$forceUpdate();
    },

    onNextStep() {
      this.currentStep++;
    },
    onPreviousStep() {
      this.currentStep--;
    },

    onSubmit() {
      this.updateIsBusy(true);
      this.errors = {};
      this.$claim
        .submitClaim(this.isPublic)
        .then(claim => {
          this.updateClaim(claim);

          this.claimNumber = claim.claimKey;
          this.updateIsBusy(false);
          this.isClaimSubmitted = true;
          this.currentStep++;
          this.$emit("claim");
        })
        .catch(errors => {
          this.errors = this.$api.getErrorsFromResponse(errors);
          this.$vuetify.goTo(0);
          this.updateIsBusy(false);
          setTimeout(() => {
            this.validateAllForms();
          }, 1000);
        });
    },

    async fetchClaimData(claimId) {
      let { data } = await this.$api.get("/api/claims/" + claimId);
      this.updateClaim(data);
      await Promise.all([
        this.fetchClaimFieldValues(claimId),
        this.fetchClaimProducts(claimId),
        this.fetchClaimDocumentationAndUploads(data),
        this.fetchParticipantSelectedToClaimOnBehalf(data)
      ]);
    },

    fetchClaimFieldValues(claimId) {
      return this.$api.get("/api/claimFieldValues/byClaimId/" + claimId + "?size=100").then(({ data }) => {
        let claimFieldValues = [];
        data.content.forEach(claimFieldValue => {
          claimFieldValues[claimFieldValue.claimFieldId] = claimFieldValue.value;
        });
        this.updateClaimFieldValues(claimFieldValues);
      });
    },

    fetchClaimProducts(claimId) {
      return this.$api.get("/api/claimProducts/byClaimId/" + claimId + "?size=500&page=0").then(({ data }) => {
        this.updateClaimProducts(data.content);
      });
    },

    fetchClaimDocumentationAndUploads(claim) {
      return this.$api.getRelatedObject("uploads", claim).then(({ data }) => {
        if (data && data._embedded && data._embedded.uploads && data._embedded.uploads.length > 0) {
          let files = data._embedded.uploads;
          if (files.length == 1) {
            this.updateClaimUploads({ existing: [data._embedded.uploads[0]], deleted: [] });
          } else {
            this.updateDocumentation({ existing: [data._embedded.uploads.slice(0, -1)], deleted: [] });
            this.updateClaimUploads({ existing: data._embedded.uploads.slice(-1), deleted: [] });
          }
        }
      });
    },

    fetchParticipantSelectedToClaimOnBehalf(claim) {
      return this.$api.getRelatedObject("participant", claim).then(({ data }) => {
        this.updateParticipantSelectedToClaimOnBehalf(data);
      });
    },

    breadcrumb() {
      if (this.promotion.name) {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim - " + this.promotion.name + " (" + this.promotion.promotionKey + ")" }
        ]);
      } else {
        this.$store.dispatch("setBreadcrumb", [
          {
            text: this.$i18n.translate("Dashboard"),
            to: { name: "dashboard" },
            exact: true
          },
          {
            text: "Eligible Promotions",
            to: { name: "cprClaims" },
            exact: true
          },
          { text: "Claim" }
        ]);
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onClear() {
      this.participantSelectedToClaimOnBehalf = null;
    },
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    },

    fetchClaimFieldsForPromotion(promotion) {
      let claimType = promotion.claimType;
      return this.$api
        .get("/api/claimFields/byClaimTypeId/" + claimType.id + "?sort=rankOrder,ASC&size=200")
        .then(({ data }) => {
          return data.content;
        });
    },
    updateOptIn(value) {
      console.log(value);
    },
    emitLastStepGtag() {
      if (this.flags.emitLastStepGtag) {
        this.$emit("lastStepChange", this.getStepKey);
      }
    }
  },
  beforeCreate() {
    this.$store.dispatch(RESET_CLAIM_STATE);
  },
  watch: {
    claimId: {
      immediate: true,
      handler(v) {
        this.updateClaimId(v);
      }
    },
    currentStep: {
      immediate: true,
      handler(v) {
        if (v) {
          this.emitLastStepGtag();
        }
        this.$forceUpdate();
        this.$emit("stepChange");
      }
    },
    modeConfig: {
      deep: true,
      handler(v) {
        let mode = stepsBuilder.getMode(v);
        this.updateMode(mode);
        this.steps = stepsBuilder.getClaimStepsForMode(mode, v);
        this.$set(this, "currentStep", 1);
      }
    },
    public: {
      immediate: true,
      handler(v) {
        this.updateIsPublic(v);
      }
    },
    strings: {
      immediate: true,
      handler(v) {
        this.updateStrings(v);
      }
    },
    objects: {
      immediate: true,
      handler(v) {
        this.updateObjects(v);
      }
    },
    flags: {
      immediate: true,
      handler(v) {
        this.updateFlags(v);
      }
    },
    rules: {
      immediate: true,
      handler(v) {
        this.updateRules(v);
      }
    },
    productsToRegister: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localClaimProducts = v;
      }
    }
  },
  computed: {
    ...mapGetters([
      "selectedParticipant",
      "selectedProgram",
      "selectedClient",
      "selectedLocale",
      "availableAddresses",
      "claimFieldValues",
      "claimFieldValue",
      "claimFields",
      "claimProducts",
      "productsToRegister",
      "claimUploads",
      "documentation",
      "formattedZeroSalesPeriod",
      "participantSelectedToClaimOnBehalf",
      "saveEndUserForLater",
      "selectedEndUser",
      "selectedZeroSalesPeriod",
      "zeroSalesItems",
      "bypassZeroSales",
      "invitationOnly",
      "mode",
      "isBusy",
      "optIn"
    ]),
    blockScreen() {
      if (this.hasInvalidKey) {
        return {
          message:
            "It looks like the invitation to this Promotion is invalid. If you're still having trouble, please contact the person who sent you the invitation to verify that the invitation is correct.",
          title: "Promotion Key Invalid"
        };
      }
      if (
        this.promotion &&
        this.promotion.program &&
        this.promotion.program.id &&
        this.selectedProgram &&
        this.selectedProgram.id != this.promotion.program.id
      ) {
        return {
          message: "The promotion's program doesn't match the selected program.",
          title: "Program mismatch"
        };
      }

      return null;
    },
    modeConfig() {
      let config = {
        promotion: this.promotion,
        participant: this.selectedParticipant,
        enableOcr: this.enableOcr,
        documentationRequired: this.documentationRequired,
        bypassZeroSales: this.bypassZeroSales,
        public: this.public,
        allowProductRegistration: false
      };
      return config;
    },
    editableSteps() {
      let editable = [];
      let aux = true;
      for (let valid of this.valid) {
        if (!valid) aux = false;
        editable.push(aux);
      }
      return editable;
    },
    documentationRequired() {
      return (
        !this.participantSkipClaimDocumentation &&
        !this.promotionSkipClaimDocumentation &&
        this.promotionTypeDocumentationRequired
      );
    },
    isPublic() {
      return this.public;
    },
    getStepKey() {
      return this.steps[this.currentStep - 1].stepKey;
    },
    countryMismatchWarningMessage() {
      return "The country of this promotion does not match your locale country. Please review the promotion details carefully.";
    }
  },

  async created() {
    await this.fetchData();
    if (this.invitationOnly) {
      await this.$claim
        .verifyPromotionInvitationKey(this.$route.query.invitationKey, this.public)
        .then(data => {
          this.hasInvalidKey = false;
          this.participantEmail = data.data.participantEmail;
        })
        .catch(() => {
          this.hasInvalidKey = true;
        })
        .finally(() => {
          this.isLoaded = true;
          return Promise.resolve();
        });
    }
    let mode = stepsBuilder.getMode(this.modeConfig);
    this.updateMode(mode);
    this.steps = stepsBuilder.getClaimStepsForMode(mode, this.modeConfig);
    this.isLoaded = true;
    this.$set(this, "currentStep", 1);
    setTimeout(() => {
      if (this.claimId) {
        this.$root.$emit("ClaimCompleteInformationEntryStep-validate");
      }
    }, 2000);
  },
  deactivated() {
    this.resetClaimState();
  },
  beforeDestroy() {
    this.resetClaimState();
  }
};
</script>
