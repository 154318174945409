<template>
  <v-container>
    <v-row v-if="isLoading">
      <div class="text-center pa-5">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-row>
    <v-row v-else-if="availableAwardVehicles && availableAwardVehicles.length > 0">
      <v-col cols="12" v-if="availableAwardVehicles.length > 1">
        <v-alert type="info"> There are multiple {{ $i18n.translate("Payout") }} methods available to you! </v-alert>
      </v-col>
      <v-col cols="12">
        <v-alert v-if="requirePreferredAwardVehicle" border="top" type="warning">
          Please select a preferred {{ $i18n.translate("Payout") }} method.
        </v-alert>
      </v-col>
      <v-col md="4" v-for="awardVehicle of availableAwardVehicles" :key="awardVehicle.awardVehicleKey">
        <AwardVehicleCard
          :awardVehicle="awardVehicle"
          :customAddress="customAddress"
          :selected="
            selectedAwardVehicle ? awardVehicle.awardVehicleKey == selectedAwardVehicle.awardVehicleKey : false
          "
          @selected="onSelect"
          @unselected="onUnselect"
          @bankAccount="onBankAccount"
          :buttonSelectText="buttonSelectText"
          :buttonSelectedText="buttonSelectedText"
          :primaryBankAccount="primaryBankAccount"
          :claimAmount="claimAmount"
          :public="isPublic"
          :class="
            selectedAwardVehicle && awardVehicle.awardVehicleKey == selectedAwardVehicle.awardVehicleKey
              ? 'preferred-award-vehicle-card'
              : ''
          "
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-alert dense type="info">
          Your {{ $i18n.translate("Participant") }} level currently has no ways to earn in the
          {{ $i18n.translate("Program") }}.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AwardVehicleCard from "@/gapp-components/components/common/awardVehicle/AwardVehicleCard";

export default {
  name: "AwardVehicleSelectionPanel",
  components: {
    AwardVehicleCard
  },
  data: () => ({
    isLoading: true,
    selectedAwardVehicle: null,
    primaryBankAccount: null
  }),
  props: {
    availableAwardVehicles: { type: Array },
    value: Object,
    buttonSelectedText: {
      type: String,
      default: "Selected"
    },
    public: {
      type: Boolean,
      default: false
    },
    buttonSelectText: {
      type: String,
      default: "Select"
    },
    claimAmount: Number,
    customAddress: {
      type: Object
    }
  },
  methods: {
    onSelect(awardVehicle) {
      this.selectedAwardVehicle = awardVehicle;
    },
    onUnselect() {
      this.selectedAwardVehicle = null;
    },
    fetchPrimaryBankAccount() {
      if (!this.isPublic) {
        this.isLoading = true;
        return this.$api
          .post("/api/bankAccounts/search?size=1", { isEffective: "Active", primary: true })
          .then(({ data }) => {
            if (data.content && data.content.length == 1) {
              this.primaryBankAccount = data.content[0];
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    onBankAccount(bankAccount) {
      this.primaryBankAccount = bankAccount;
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "impersonationParticipant"]),
    isPublic() {
      return this.public;
    },
    requirePreferredAwardVehicle() {
      return this.selectedParticipant?.participantType?.allowPreferredAwardVehicle && !this.selectedAwardVehicle;
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.selectedAwardVehicle = v;
      }
    },
    selectedAwardVehicle: {
      deep: true,
      handler(v) {
        this.$emit("input", v);
      }
    }
  },
  mounted() {
    this.fetchPrimaryBankAccount();
  }
};
</script>
