var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"mainForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{staticClass:"mt-0"},[_c('v-col',[_c('v-autocomplete',{ref:"menu",attrs:{"dense":"","placeholder":_vm.strings.productListText,"outlined":"","items":_vm.promotion && _vm.promotion.promotionProducts ? _vm.sortedProductsAlphabetically(_vm.promotion.promotionProducts) : [],"label":"Add a Product","item-value":"id","item-text":function (item) {
              return item && item.product ? item.product.productKey : '';
            },"multiple":"","return-object":"","attach":"","auto":"","search-input":_vm.searchInput,"menu-props":{ zIndex: '9999' }},on:{"input":function($event){_vm.searchInput = null},"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[_c('v-btn',{staticClass:"primary mt-n2",on:{"click":function($event){_vm.onAddProduct();
                  _vm.closeMenu();}}},[_vm._v(" Add ")])],1)]},proxy:true}]),model:{value:(_vm.selectedPromotionProducts),callback:function ($$v) {_vm.selectedPromotionProducts=$$v},expression:"selectedPromotionProducts"}})],1)],1),_c('v-col',[_c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',[_c('v-data-table',{attrs:{"headers":_vm.claimProductHeaders,"items":_vm.localTradeInProducts
                  ? _vm.localTradeInProducts.filter(function (cp) { return cp.promotionProduct.product.productKey == 'TRADE_IN_PRODUCT'; })
                  : null,"item-class":_vm.itemBackgroundColor,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.promotionProduct.product.productKey",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('span',{staticClass:"productKeyField"},[_vm._v(_vm._s(item.promotionProduct.product.productKey))])]),(_vm.askForPurchaseDate || _vm.askForPurchasePrice)?_c('v-col',{attrs:{"cols":12}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDeleteProduct(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete Product")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDuplicateProduct(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-duplicate ")])]}}],null,true)},[_c('span',[_vm._v("Duplicate Product")])])],1):_vm._e()],1)]}},{key:"item.serialNumber",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{staticClass:"py-2"},[_c('v-col',{attrs:{"cols":12}},_vm._l((_vm.fieldRows),function(row,rowIndex){return _c('v-row',{key:'field-row-' + rowIndex},_vm._l((row),function(fieldData){return _c('v-col',{key:'field-' + fieldData.index,attrs:{"cols":"12","sm":"6"}},[(
                            item &&
                              item.promotionProduct &&
                              item.promotionProduct[fieldData.enableFieldKey] &&
                              _vm.selectedProgram &&
                              _vm.selectedProgram.claimProductCustomFields[fieldData.index - 1]
                          )?_c('CustomField',{attrs:{"customField":fieldData.field},model:{value:(item['customFieldValue' + fieldData.index]),callback:function ($$v) {_vm.$set(item, 'customFieldValue' + fieldData.index, $$v)},expression:"item['customFieldValue' + fieldData.index]"}}):_vm._e()],1)}),1)}),1)],1)]}},(!_vm.askForPurchaseDate && !_vm.askForPurchasePrice)?{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDeleteProduct(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Product")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 ml-2",on:{"click":function($event){return _vm.onDuplicateProduct(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-duplicate ")])]}}],null,true)},[_c('span',[_vm._v("Duplicate Product")])])]}}:null],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.showAddAnotherDialog),callback:function ($$v) {_vm.showAddAnotherDialog=$$v},expression:"showAddAnotherDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Add Another Trade-In Item?")]),_c('v-card-text',[_vm._v("Do you want to add another trade-in item to this invoice?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"green darken-1","text":""},on:{"click":function($event){return _vm.handleAddAnotherProduct(true)}}},[_vm._v(" Yes ")]),_c('v-btn',{attrs:{"loading":_vm.isLoading,"color":"red darken-1","text":""},on:{"click":function($event){return _vm.handleAddAnotherProduct(false)}}},[_vm._v(" No ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }