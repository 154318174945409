let claimSteps = {
  BASIC: [
    "CLAIM_COMPLETE_INFORMATION_ENTRY",
    "AWARD_VEHICLE_SELECTION",
    "PRODUCT_REGISTRATION",
    "CLAIM_DETAILS",
    "SUCCESS"
  ],
  ZERO_SALES: [
    "ZERO_SALES_ENTRY",
    "SUPPORTING_DOCUMENTATION",
    "AWARD_VEHICLE_SELECTION",
    "CLAIM_DETAILS",
    "EARNING",
    "SUCCESS"
  ],
  OCR: [
    "OCR_INVOICE_UPLOAD",
    "CLAIM_COMPLETE_INFORMATION_ENTRY",
    "PRODUCT_REGISTRATION",
    "AWARD_VEHICLE_SELECTION",
    "CLAIM_DETAILS",
    "SUCCESS"
  ]
};

let steps = [
  {
    stepKey: "PROMOTION_INVITATION",
    name: "Promotion Invitation",
    showInHeader: true
  },
  {
    stepKey: "ZERO_SALES_ENTRY",
    nameL: "Details",
    showInHeader: true
  },
  {
    stepKey: "OCR_INVOICE_UPLOAD",
    name: "Upload Invoice",
    showInHeader: true
  },
  {
    stepKey: "CLAIM_COMPLETE_INFORMATION_ENTRY",
    name: "Invoice Details",
    showInHeader: true
  },
  {
    stepKey: "CLAIM_PRODUCTS_ENTRY",
    name: "Products",
    showInHeader: true
  },
  {
    stepKey: "SUPPORTING_DOCUMENTATION",
    name: "Supporting Documentation",
    showInHeader: true
  },
  {
    stepKey: "PRODUCT_REGISTRATION",
    name: "Product Registration",
    showInHeader: true
  },
  {
    stepKey: "AWARD_VEHICLE_SELECTION",
    name: "Payout Selection",
    showInHeader: true
  },
  {
    stepKey: "CLAIM_DETAILS",
    name: "Review",
    showInHeader: true
  },
  {
    stepKey: "SUCCESS",
    name: "Success",
    showInHeader: false
  }
];
export function getClaimStepsForMode(mode, config) {
  let claimSteps = null;
  switch (mode) {
    case "OCR":
      claimSteps = getClaimStepsForOcr(config);
      break;
    case "ZERO_SALES":
      claimSteps = getClaimStepsForZeroSales(config);
      break;
    case "BASIC":
      claimSteps = getClaimStepsForBasic(config);
      break;
  }
  return claimSteps;
}

export function getMode(config) {
  const { promotion, enableOcr } = config;
  let mode = null;
  if (enableOcr) {
    mode = "OCR";
  } else {
    if (promotion && promotion.claimType && promotion.claimType.allowZeroSales && !config.bypassZeroSales) {
      mode = "ZERO_SALES";
    } else {
      mode = "BASIC";
    }
  }
  return mode;
}

export function getClaimStepsForBasic(config) {
  let basicClaimSteps = claimSteps["BASIC"].map(elem => steps.find(elem2 => elem2.stepKey == elem));
  if (!config.documentationRequired) {
    basicClaimSteps = basicClaimSteps.filter(elem => elem.stepKey != "SUPPORTING_DOCUMENTATION");
  }
  if (!config.allowProductRegistration) {
    basicClaimSteps = basicClaimSteps.filter(elem => elem.stepKey != "PRODUCT_REGISTRATION");
  }
  return basicClaimSteps;
}

export function getClaimStepsForZeroSales(config) {
  let zeroSalesClaimSteps = claimSteps["ZERO_SALES"].map(elem => steps.find(elem2 => elem2.stepKey == elem));
  if (!config.documentationRequired) {
    zeroSalesClaimSteps = zeroSalesClaimSteps.filter(elem => elem.stepKey != "SUPPORTING_DOCUMENTATION");
  }
  return zeroSalesClaimSteps;
}

export function getClaimStepsForOcr(config) {
  let ocrClaimStep = claimSteps["OCR"].map(elem => steps.find(elem2 => elem2.stepKey == elem));
  if (!config.documentationRequired) {
    ocrClaimStep = ocrClaimStep.filter(elem => elem.stepKey != "SUPPORTING_DOCUMENTATION");
  }
  if (!config.allowProductRegistration) {
    ocrClaimStep = ocrClaimStep.filter(elem => elem.stepKey != "PRODUCT_REGISTRATION");
  }
  return ocrClaimStep;
}
