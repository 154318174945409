<template>
  <div>
    <v-select
      :value="selectedItem"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :loading="loading"
      :items="items"
      hide-no-data
      item-value="id"
      clearable
      return-object
      no-filter
      ref="select"
      label="You can select a previous End User"
      no-data-text="No previous end user available"
    >
      <template v-slot:item="{ item }">
        {{ item.firstName }} {{ item.lastName }} - {{ item.address.city }}
        {{ item.address.region ? item.address.region.isoAlpha2 : "" }} {{ item.address.postalCode }}
        {{ item.address.country ? item.address.country.isoAlpha3 : "" }}
        <v-spacer></v-spacer>
        <v-icon
          @click.stop="
            endUserToDelete = item;
            showDeleteDialog = true;
          "
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:selection="{ item }">
        {{ item.firstName }} {{ item.lastName }} - {{ item.address.city }}
        {{ item.address.region ? item.address.region.isoAlpha2 : "" }} {{ item.address.postalCode }}
        {{ item.address.country ? item.address.country.isoAlpha3 : "" }}
      </template>
    </v-select>
    <v-dialog v-model="showDeleteDialog" max-width="500">
      <v-card>
        <v-card-title>Confirm</v-card-title>

        <v-card-text>Do you want to delete this End User?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="showDeleteDialog = false">{{ $i18n.translate("Cancel") }}</v-btn>
          <v-btn
            class="primary"
            @click="
              deleteEndUser();
              showDeleteDialog = false;
            "
            >Accept</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "EndUserField",
  props: {
    value: Object,
    showInfoButton: {
      type: Boolean,
      default: true
    },
    participantId: {
      type: Number
    }
  },
  data: () => ({
    showBottomMessage: true,
    selectedItem: {},
    loading: false,
    items: [],
    showDeleteDialog: false,
    endUserToDelete: null
  }),
  methods: {
    onInput(obj) {
      if (obj && obj.id) {
        this.$emit("input", obj);
      }
    },
    clear() {
      this.reload = false;
      this.$nextTick(() => {
        this.reload = true;
      });
    },
    deleteEndUser() {
      ApiService.post("/api/claimEndUsers/delete/" + this.endUserToDelete.id)
        .then(() => {
          this.selectedItem = null;
          this.$emit("delete", this.endUserToDelete);
          this.fetchData();
        })
        .catch(e => console.log(e));
    },
    fetchData() {
      if (this.participantId) {
        return ApiService.post("/api/claimEndUsers/byParticipantId/" + this.participantId).then(({ data }) => {
          this.items = data;
          this.$emit("loaded", data);
        });
      } else {
        this.items = [];
        this.$emit("loaded", this.items);
        return Promise.resolve([]);
      }
    }
  },
  watch: {
    participantId() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
