<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>Submit zero sales?</h3>
        <v-radio-group v-model="q1">
          <v-radio value="no" label="No"></v-radio>
          <v-radio value="yes" label="Yes"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="q1 == 'yes'">
      <v-col cols="4">
        Select Period:<br />
        <v-select
          :items="zeroSalesItems"
          :value="selectedZeroSalesPeriod"
          @input="updateSelectedZeroSalesPeriod($event)"
        ></v-select
        ><br />
      </v-col>
    </v-row>
    <v-row>
      <v-btn
        @click="
          if (q1 == 'yes') {
            $emit('nextStep');
          } else {
            updateBypassZeroSales(true);
          }
        "
        class="primary"
        :disabled="!q1 || (q1 == 'yes' && !selectedZeroSalesPeriod)"
        >Continue</v-btn
      >
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters, mapActions } from "vuex";
import UtilService from "@/gapp-components/services/util.service.js";

export default {
  name: "ZeroSalesStep",
  created() {
    this.loadZeroSalesMonths();
  },
  data: () => ({ q1: false, zeroSales: true }),
  methods: {
    ...mapActions([
      "updateBypassZeroSales",
      "updateZeroSalesItems",
      "updateSelectedZeroSalesPeriod",
      "updateFormattedZeroSalesPeriod"
    ]),
    loadZeroSalesMonths() {
      let zeroSalesItems = [];
      let month = moment();
      month.subtract(1, "months");
      for (let i = 0; i < 3; i++) {
        zeroSalesItems.push({
          text: month.format("MMMM YYYY"),
          value: month.format("YYYY-MM-[01]")
        });
        month.add(1, "months");
      }
      this.updateZeroSalesItems(zeroSalesItems);
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "zeroSalesItems", "selectedZeroSalesPeriod", "formattedZeroSalesPeriod"])
  },
  watch: {
    selectedZeroSalesPeriod: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!v) return;
        this.updateFormattedZeroSalesPeriod(
          UtilService.getDateInClientTimezone(this.selectedZeroSalesPeriod).format("MMMM YYYY")
        );
      }
    }
  }
};
</script>

<style></style>
