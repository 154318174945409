let promotion = null;
import ApiService from "../../../services/api.service";

export async function fetchPromotionById(id) {
  await ApiService.get("/api/promotions/" + id).then(({ data }) => {
    promotion = data;
  });

  await Promise.all([
    fetchPromotionTypeForPromotion(promotion),
    fetchClaimTypeForPromotion(promotion),
    fetchPromotionProductsForPromotion(promotion),
    fetchPromotionDeadlinesForPromotion(promotion)
  ]);
  return promotion;
}

export async function fetchPublicPromotionById(id) {
  await ApiService.get("/api/promotions/" + id + "/public").then(({ data }) => {
    promotion = data;
  });

  return promotion;
}

export async function fetchPublicPromotionByKey(key) {
  await ApiService.get("/api/promotions/byPromotionKey/" + key + "/public").then(({ data }) => {
    promotion = data;
  });

  return promotion;
}

export function fetchPromotionTypeForPromotion(promotion) {
  return ApiService.getRelatedObject("promotionType", promotion).then(({ data }) => {
    promotion.promotionType = data;
    if (promotion.promotionType.uploadLimit > 0) {
      promotion.promotionTypeDocumentationRequired = true;
    }
  });
}

export function fetchClaimTypeForPromotion(promotion) {
  return ApiService.getRelatedObject("claimType", promotion).then(({ data }) => {
    promotion.claimType = data;
  });
}

export function fetchPromotionProductsForPromotion(promotion) {
  return ApiService.post("/api/promotionProducts/search?size=500&page=0&nestedSort=product.productKey,ASC", {
    promotion: { id: promotion.id },
    isEffective: true
  }).then(({ data }) => {
    promotion.promotionProducts = data.content.filter(item => item.effective == true);
  });
}

export function fetchPromotionDeadlinesForPromotion(promotion) {
  return ApiService.post("/api/promotionDeadlines/search", { promotion: { id: promotion.id } }).then(({ data }) => {
    promotion.promotionDeadlines = data;
  });
}
