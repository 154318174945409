<template>
  <v-container>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="12">
        <v-card v-if="showLandingPage">
          <v-toolbar flat>
            <v-toolbar-title class="headline">{{ promotion.name }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="showLandingPage = false">Continue</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="justify-center">
              <v-col lg="4" md="6" sm="12" xs="12" cols="auto">
                <v-card style=" display: flex !important; flex-direction: column;" class="cardStyle firstCard">
                  <v-card-title>Important Dates</v-card-title>
                  <v-card-text style="flex-grow: 1; overflow: auto;">
                    <div class="scrollable-table">
                      <v-data-table
                        :headers="[
                          {
                            text: this.$i18n.translate('Type'),
                            value: 'type',
                            align: 'right',
                            sortable: false
                          },
                          {
                            text: this.$i18n.translate('Date'),
                            value: 'date',
                            sortable: false
                          }
                        ]"
                        :items="importantDatesTableData"
                        class="elevation-1"
                        hide-default-footer
                        dense
                      >
                        <template v-slot:item.type="{ item }">
                          <b>{{ item.type }}</b>
                        </template>
                        <template v-slot:item.date="{ item }">
                          {{ item.date | formatDateClient("MM/DD/YYYY", selectedClient) }}
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col lg="4" md="6" sm="12" xs="12" cols="auto">
                <v-row>
                  <v-col cols="12">
                    <v-card style=" display: flex !important;flex-direction: column;" class="cardStyle secondCard">
                      <v-card-title>Offer Details</v-card-title>
                      <v-card-text style="flex-grow: 1; overflow: auto;">
                        <div v-html="promotion.description"></div>
                      </v-card-text>
                      <v-card-text
                        class="text-center"
                        v-if="
                          promotion.promotionType &&
                            promotion.promotionType.promotionTypeKey != 'PROMOTION_IMAGING_EDGE'
                        "
                      >
                        <img alt="Acceptable UPC Codes" width="90%" src="../../assets/acceptableUpcCodesV2.png" />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col lg="4" md="12" sm="12" xs="12" cols="auto">
                <v-card class="cardStyle thirdCard" ref="container">
                  <v-card-title
                    >Promotion Payouts
                    <v-spacer></v-spacer>
                    <v-text-field
                      class="mr-2"
                      v-model="searchPromotionProduct"
                      :label="$i18n.translate('Search') + ' ' + $i18n.translate('Promotion Product')"
                      flat
                      solo-inverted
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-layout column>
                      <v-flex md6 style="overflow: auto">
                        <v-data-table
                          :search="searchPromotionProduct"
                          :loading="loading"
                          :headers="productPayoutHeaders"
                          :items="
                            selectedProgram.programKey != 'SONY-CEUR'
                              ? expandedProducts[promotion.id]
                              : sortedProductsAlphabetically(expandedProducts[promotion.id])
                          "
                          :no-data-text="$i18n.translate('No eligible products')"
                          class="elevation-1"
                          :options.sync="optionsPayouts"
                          :items-per-page="payoutTableItemsPerPage"
                          :footer-props="{ disableItemsPerPage: false, itemsPerPageOptions: [payoutTableItemsPerPage] }"
                          fixed-header
                          ref="dataTable"
                        >
                          <template v-slot:item.categoryName="{ item }">
                            {{
                              item.product.productCategory
                                ? item.product.productCategory.publicName
                                  ? item.product.productCategory.publicName
                                  : item.product.productCategory.name
                                : ""
                            }}
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="showLandingPage = false">Continue</v-btn>
          </v-card-actions>
        </v-card>
        <ClaimForm
          id="claimForm"
          :promotionId="promotion.id"
          v-if="!showLandingPage"
          @stepChange="$vuetify.goTo(0)"
          @lastStepChange="lastStepChange"
          @onCancel="onCancel"
          :claimId="$route.query.claimId"
          :public="true"
          :enableOcr="true"
          :isOptInRequired="true"
          :showTermsAndConditions="true"
          publicParticipantTypeKey="950"
          :askForPurchaseDate="true"
          :askForPurchasePrice="true"
          :contentConfig="claimFormContentConfig"
          @claim="$gtag.event('submit', { page: 'product-registration' })"
          :forcedAwardVehicles="[
            { awardVehicleKey: 'EUR_PREPAID_AWARD_VEHICLE', name: 'Single Load Debit Card' },
            { awardVehicleKey: '4fb624dd-6024-4938-991a-113872c45b57', name: 'ACH - Direct Deposit' }
          ]"
          :flags="flags"
          :strings="strings"
          :objects="objects"
          :rules="rules"
          :reminders="[
            {
              text:
                'For all Sony product, except Xperia, the middle-seven digits should be entered (e.g. S01-4036705-B -> 4036705)'
            },
            {
              text:
                this.selectedProgram && this.selectedProgram.programKey != 'SONY-CEUR'
                  ? 'For Xperia product, the full IMEI is required (e.g. 35242794-019573-7)'
                  : null
            }
          ]"
          :showExampleImages="true"
        >
          <template v-slot:example-images>
            <v-img src="../../assets/all-sony-product-except-xperia.png" />
            <v-img v-if="selectedProgram.programKey != 'SONY-CEUR'" src="../../assets/for-xperia-only.png" />
          </template>

          <template v-slot:payout-instructions v-if="selectedProgram.programKey == 'SONY-CEUR'">
            <li>
              All Prepaid Cards will be mailed to your physical address
            </li>
          </template>

          <template v-slot:askForSubscriptionSlot="slotProps">
            <v-card>
              <v-card-title primary-title>
                {{ $i18n.translate("Sony email communications") }}
              </v-card-title>

              <v-card-text v-if="selectedCountry.name == 'USA' && selectedLocale.languageType.name == 'en'">
                <p>
                  <v-checkbox
                    :input-value="slotProps.optInValue"
                    label="Yes, I would like to receive email marketing communications from Sony Electronics Inc. about products and services that may be of interest to me."
                    @change="slotProps.updateOptIn($event)"
                  ></v-checkbox>

                  By checking the box, I agree to the Sony Electronics Inc
                  <a href="https://electronics.sony.com/privacy-policy" target="_blank">Privacy Policy </a>and certify
                  that I am a U.S. resident.
                  <a href="https://electronics.sony.com/privacy-policy#DataPractices" target="_blank"
                    >CA Privacy Notice</a
                  >
                </p>
              </v-card-text>
              <v-card-text v-if="selectedCountry.name == 'CAN' && selectedLocale.languageType.name == 'en'">
                <p>
                  <v-checkbox
                    :input-value="slotProps.optInValue"
                    label="Yes, I would like to receive marketing communications from Sony of Canada. Ltd. about products or services, promotions and events that may be of interest to me."
                    @change="slotProps.updateOptIn($event)"
                  ></v-checkbox>

                  I understand that I can unsubscribe at any time. For more information, please refer to the Sony of
                  Canada
                  <a href="https://corporate.sony.ca/view/privacy.htm" target="_blank">Privacy Policy </a> or
                  <a href="https://corporate.sony.ca/view/contact_info.htm" target="_blank">Contact Sony</a>
                </p>
              </v-card-text>
            </v-card>
          </template>
        </ClaimForm>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClaimForm from "../../gapp-components/components/forms/claim/ClaimForm.vue";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import moment from "moment-timezone";

export default {
  components: { ClaimForm },
  name: "claimProductRegistration",
  metaInfo: {
    title: "Claim Product Registration"
  },
  data: () => ({
    step: "1",
    valid: {
      step1: false,
      step2: false,
      step3: false,
      step4: false
    },
    promotionKey: "9000019036",
    optionsPayouts: {
      sortBy: ["payout"],
      sortDesc: [true]
    },
    aboveRouterViewContentHeight: 0,
    payoutTableItemsPerPage: 10,
    expandedProducts: [],
    isBusy: false,
    isNew: false,
    isEditing: true,
    promotionId: 0,
    errors: {},
    leavingStep: "LANDING_PAGE",
    serialNumberRegex: "",
    showLandingPage: true,
    promotion: {},
    searchPromotionProduct: "",
    loading: false,
    claimFields: [],
    claimFieldsByRow: [],
    claimFieldValues: {},
    defaultTermsAndConditions: [],
    selectedPromotionProducts: [],
    claimProducts: [],
    claimProductHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: "Model",
        align: "center"
      },
      {
        value: "serialNumber",
        text: "",
        sortable: false
      },
      {
        value: "actions",
        text: "Actions",
        align: "center",
        sortable: false
      }
    ],

    claimSubmitted: false,

    claimStage: null,

    claimUploads: {
      existing: [],
      deleted: []
    },

    localRules: {
      required: [v => !!v || "Field is required"],
      participant: [v => !!v || "Participant is required"],
      quantity: [v => !!v || "Quantity is required"],
      terms: [v => !!v || "Terms is required"]
    },

    form: {},
    uploads: {},
    uploadedFilesDialog: false,

    participantSelectedToClaimOnBehalf: null,
    claimNumber: "",
    q1: false,
    zeroSales: false,
    zeroSalesItems: [],
    selectedZeroSalesPeriod: null,
    formattedZeroSalesPeriod: null,
    saveEndUserForLater: false,
    selectedEndUser: null,

    // preferredAwardVehicle: undefined,
    availableAwardVehicles: [],

    participantSkipClaimDocumentation: false,
    promotionSkipClaimDocumentation: false,
    promotionTypeDocumentationRequired: false,
    supportingDocumentation: true,
    terms: false,
    examplesDialog: false,
    dialogImage: require("@/assets/sample-barcodes.png")
  }),
  deactivated() {
    this.resetClaimState();
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.onBackButtonClick);
    window.removeEventListener("beforeunload", this.userLeaving);

    this.resetClaimState();
  },
  created() {
    this.fetchData();
    if (this.$gtag) {
      this.$gtag.event("access", { page: "product-registration" });
    } else {
      console.log("gtag is not defined.");
    }
    this.$root.$on("backToLandingPage", () => {
      this.showLandingPage = true;
      history.replaceState({ backClicked: true }, null, null);
    });
  },
  mounted() {
    history.pushState({ backClicked: false }, null, null);

    window.addEventListener("beforeunload", this.userLeaving);
    window.addEventListener("popstate", this.onBackButtonClick);

    this.doAboveRouterViewContentHeightCalculation();
    if (this.aboveRouterViewContentHeight == 0) {
      setTimeout(() => this.doAboveRouterViewContentHeightCalculation(), 5000);
    }
    this.setPayoutTableItemsPerPage();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedCountry", "selectedProgram", "selectedLocale", "selectedClient"]),

    claimFormContentConfig() {
      return {
        component: { styles: {} },
        mainStepper: { styles: { overflow: "visible" } },
        mainStepperHeader: {
          styles: {
            position: "sticky",
            top: this.aboveRouterViewContentHeight + "px",
            zIndex: 2,
            backgroundColor: "white"
          }
        }
      };
    },

    strings() {
      return {
        mask: "!S01-#######",
        claimProductsSerialnumberTooltipText:
          "Serial Numbers can be found on the outside of the original product packaging. Your sales invoices may also contain this detail. The middle -seven digits should be entered.",
        errorsAdditionalMessage:
          "Need Help? Contact Program Headquarters: <br>" +
          "Email: <a target='_blank' href='mailto:" +
          this.selectedProgram.programEmail +
          "'>" +
          this.selectedProgram.programEmail +
          "</a><br>" +
          "Phone: " +
          this.selectedProgram.programPhone +
          "<br><br>",
        prepaidCardsMessage:
          this.selectedProgram &&
          this.selectedProgram.programKey == process.env.VUE_APP_PROGRAM_CEUR_KEY &&
          this.selectedCountry.name == "CAN"
            ? "If potential earnings exceed $999 CAD, payments will be issued via multiple cards"
            : null,
        productListText:
          this.selectedProgram && this.selectedProgram.programKey == process.env.VUE_APP_PROGRAM_CEUR_KEY
            ? "Add products - select all that apply"
            : null,
        additionalDocumentationLabel: "Upload additional documentation – UPC Codes and Purchase Receipts"
      };
    },
    flags() {
      return {
        claimProductsSerialnumberTooltip: true,
        emitLastStepGtag: true,
        includeClaimProductsSerialNumberTooltip: true,
        optInDefaultValue: false,
        markPurchasePriceAsRequired:
          this.selectedProgram && this.selectedProgram.programKey != process.env.VUE_APP_PROGRAM_CEUR_KEY
      };
    },
    objects() {
      return {
        tokens: {
          "#": { pattern: /\d/ },
          X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          S: { pattern: /[a-zA-Z]/ },
          A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
          a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
          "!": { escape: true }
        }
      };
    },
    rules() {
      return {
        claimProductsPurchaseDate: [
          v => !!v || "Purchase Date is required",
          v =>
            this.$util.isEffective(this.promotion, moment(v)) ||
            "For assistance with claims processing outside of Purchase Dates contact 1.888.793.1332",
          v => moment(v).isBefore(moment()) || "Purchase Dates cannot be in the Future."
        ],
        claimProductsPriceAmount: this.selectedProgram && this.selectedProgram.programKey == "SONY-CEUR" ? [] : null
      };
    },
    productPayoutHeaders() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [
          { text: "Category", value: "categoryName", sortable: true },
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: true }
        ];
      } else {
        return [
          { text: "Category", value: "categoryName", sortable: true },
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: true },
          { text: this.$i18n.translate("Rebate"), value: "payout", sortable: true, align: "right" }
        ];
      }
    },
    importantDatesTableData() {
      const data = [];
      if (!(this.promotion && this.promotion.promotionDeadlines)) return [];
      this.promotion.promotionDeadlines.forEach(promotionDeadline => {
        if (promotionDeadline.submittable && this.isStillClaimable(promotionDeadline)) {
          data.push({
            type: this.$i18n.translate("Purchase Start Date"),
            date: promotionDeadline.effectiveDate ? promotionDeadline.effectiveDate : this.promotion.effectiveDate
          });
          data.push({
            type: this.$i18n.translate("Purchase End Date"),
            date: promotionDeadline.expirationDate ? promotionDeadline.expirationDate : this.promotion.expirationDate
          });
          if (this.promotion.promotionType.claimable) {
            data.push({
              type: this.$i18n.translate("Claim Deadline"),
              date: promotionDeadline.submissionDeadlineDate
            });
          }
        }
      });
      return data;
    },
    headers() {
      if (
        this.expandedProducts &&
        this.promotion &&
        this.expandedProducts[this.promotion.id] &&
        this.expandedProducts[this.promotion.id].every(elem =>
          elem.promotionProductPayouts.every(subelem => subelem.payoutType.name == "PRODUCT")
        )
      ) {
        return [{ text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false }];
      } else {
        return [
          { text: this.$i18n.translate("Model"), value: "product.productKey", sortable: false },
          { text: this.$i18n.translate("Rebate"), value: "payout", sortable: false }
        ];
      }
    },
    termsAndConditions() {
      let selectedLocaleName = this.selectedLocale.languageType.name;
      if (this.promotion && this.promotion.promotionType && this.promotion.promotionType.termsAndConditions) {
        let promotionTypeTermsAndConditions = this.promotion.promotionType.termsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (promotionTypeTermsAndConditions.length > 0) {
          return promotionTypeTermsAndConditions[0];
        }
      }
      if (this.defaultTermsAndConditions) {
        let programTermsAndConditions = this.defaultTermsAndConditions.filter(
          tac => tac.language.name == selectedLocaleName
        );
        if (programTermsAndConditions.length > 0) {
          return programTermsAndConditions[0];
        }
      }

      return {
        description: "Terms And Conditions error. Not specified."
      };
    }
  },
  watch: {
    claimProducts: {
      handler() {
        this.$refs.step2Form.validate();
      },
      deep: true
    },
    claimUploads: {
      handler() {
        this.$refs.uploadForm.validate();
      },
      deep: true
    },
    selectedZeroSalesPeriod() {
      this.formattedZeroSalesPeriod = moment
        .tz(this.selectedZeroSalesPeriod, this.selectedClient.timezone)
        .format("MMMM YYYY");
    },
    selectedCountry() {
      if (!this.showLandingPage) {
        this.$router.go(0);
      } else {
        this.fetchData();
      }
    }
  },
  methods: {
    ...mapActions(["resetClaimState"]),
    async fetchData() {
      this.promotionKey = this.selectedCountry.name == "CAN" ? "9000019042" : "9000019036";

      this.loading = true;

      let { data } = await this.$api.get("/api/promotions/byPromotionKey/" + this.promotionKey + "/public");
      if (data && data.promotion) {
        Vue.set(this, "promotion", data.promotion);
      }
      this.getProducts(this.promotion);
    },

    userLeaving() {
      if (this.$gtag) {
        this.$gtag.event("userLeavingSite", {
          stepName: this.leavingStep,
          program: this.selectedProgram.programKey,
          promotion: this.promotion.name,
          client: this.selectedClient.name
        });
      } else {
        console.log("gtag is not defined.");
      }
    },

    lastStepChange(stepName) {
      this.leavingStep = stepName;
    },

    doAboveRouterViewContentHeightCalculation() {
      let sumOfElementsHeights = 0;
      if (
        document &&
        document.querySelector(".v-app-bar") &&
        document.querySelector(".v-app-bar").getBoundingClientRect()
      ) {
        sumOfElementsHeights += document.querySelector(".v-app-bar").getBoundingClientRect().height;
      }
      if (
        document &&
        document.querySelector(".v-system-bar") &&
        document.querySelector(".v-system-bar").getBoundingClientRect()
      ) {
        sumOfElementsHeights += document.querySelector(".v-system-bar").getBoundingClientRect().height;
      }
      if (sumOfElementsHeights == 0) console.log("Error on aboveRouterViewContentHeight calculation");
      this.aboveRouterViewContentHeight = sumOfElementsHeights;
    },
    onBackButtonClick() {
      if (!this.showLandingPage) {
        this.leavingStep = "LANDING_PAGE";
        this.showLandingPage = true;
        history.replaceState({ backClicked: true }, null, null);
      } else {
        history.go(-1);
      }
    },
    setPayoutTableItemsPerPage() {
      let firstCard = document.querySelector(".firstCard");

      let secondCard = document.querySelector(".secondCard");

      let containerCard = document.querySelector(".cardStyle");

      let containerTitle = document.querySelector(".thirdCard .v-card__title");

      let containerHeader = document.querySelector(".thirdCard .v-data-table-header");

      let containerFooter = document.querySelector(".thirdCard .v-data-footer");

      let dataTable = this.$refs.dataTable;

      if (containerCard && containerTitle && dataTable) {
        let row = dataTable.$el.querySelector(".v-data-table .v-data-table__wrapper tbody tr");

        let singleRowHeight = row ? row.clientHeight : 48;

        // Logging each property used in the calculation
        let heightToConsider =
          firstCard.clientHeight > secondCard.clientHeight ? firstCard.clientHeight : secondCard.clientHeight;
        let containerHeight =
          heightToConsider - containerTitle.offsetHeight - containerHeader.offsetHeight - containerFooter.offsetHeight;

        let itemsThatCanFit = Math.floor(containerHeight / singleRowHeight);

        this.payoutTableItemsPerPage = itemsThatCanFit >= 5 ? itemsThatCanFit : 5;
      }
    },
    getProducts(promotion) {
      if (promotion.id in this.expandedProducts) return;
      this.isLoading = true;
      return this.$api
        .get(`/api/promotions/${promotion.id}/public`)
        .then(({ data }) => {
          const items = data.promotionProducts.map(item => ({
            ...item,
            mpgDescription: this.getMpgDescription(item),
            payout: this.calculatePayout(item.promotionProductPayouts)
          }));

          this.expandedProducts[promotion.id] = items;
          this.setPayoutTableItemsPerPage();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calculatePayout(payouts) {
      if (!payouts || payouts.length === 0) return this.$i18n.translate("No Payout");

      const payout = payouts.find(p =>
        [
          "FLAT_AMOUNT_PER_UNIT_CLAIMED",
          "PERCENT_CLAIM_AMOUNT_CLAIMED",
          "PERCENT_PRODUCT_AMOUNT_CLAIMED",
          "PRODUCT"
        ].includes(p.payoutType?.name)
      );

      if (!payout) return this.$i18n.translate("No Payout");

      const { name } = payout.payoutType || {};
      const payoutAmount = payout.payoutAmount;

      switch (name) {
        case "FLAT_AMOUNT_PER_UNIT_CLAIMED":
          return `$ ${Number(payoutAmount).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`;
        case "PERCENT_CLAIM_AMOUNT_CLAIMED":
        case "PERCENT_PRODUCT_AMOUNT_CLAIMED":
          return `${Number(payoutAmount).toFixed(2)} %`;
        case "PRODUCT":
          return this.$i18n.translate("FREE Item");
        default:
          return this.$i18n.translate("No Payout");
      }
    },
    isStillClaimable(objectWithEffectivity) {
      let now = moment();
      let active = false;
      if (objectWithEffectivity.effectiveDate && objectWithEffectivity.submissionDeadlineDate) {
        active = now.isBetween(
          moment(objectWithEffectivity.effectiveDate),
          moment(objectWithEffectivity.submissionDeadlineDate)
        );
      } else if (objectWithEffectivity.effectiveDate) {
        active = now.isSameOrAfter(objectWithEffectivity.effectiveDate);
      } else if (objectWithEffectivity.submissionDeadlineDate) {
        active = now.isSameOrBefore(objectWithEffectivity.submissionDeadlineDate);
      } else {
        active = true;
      }
      return active;
    },
    getMpgDescription(item) {
      let description =
        item && item.product && item.product.productCategory && item.product.productCategory.name
          ? item.product.productCategory.name
          : "";
      if (description.startsWith("US ")) {
        return description.slice(3);
      }

      return description;
    },
    sortedProductsAlphabetically(products) {
      if (products && products.length > 0) {
        let sortedProducts = products.slice().sort((a, b) => {
          const categoryComparison = a.mpgDescription.localeCompare(b.mpgDescription);
          if (categoryComparison === 0) {
            return a.product.productKey.localeCompare(b.product.productKey);
          } else {
            return categoryComparison;
          }
        });
        return sortedProducts;
      } else {
        return [];
      }
    },
    onCancel() {
      this.userLeaving();
      this.$router.push({ name: "ceurList" });
    }
  }
};
</script>
