<template>
  <v-card>
    <v-card-title primary-title>
      {{ $i18n.translate("Terms &amp; Conditions") }}
    </v-card-title>
    <v-card-text>
      <p
        v-if="value && value.description && value.description.length > 0"
        class="text-left"
        v-html="value.description"
      ></p>
      <v-checkbox v-model="terms" :error-messages="required ? errorMessage : ''" @change="validateTerms">
        <template v-slot:label>
          <span
            @click.stop
            v-html="
              !value || !value.acceptVerbiage || value.acceptVerbiage.length == 0
                ? 'By checking this box, I state that I have read and understood the terms and conditions.'
                : value.acceptVerbiage
            "
          ></span>
        </template>
      </v-checkbox>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TermsAndConditionsView",
  props: {
    value: Object,
    required: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    terms: false,
    errorMessage: "You must accept the terms and conditions."
  }),

  watch: {
    terms: {
      handler(v) {
        this.$emit("update:valid", v);
      }
    },
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("update:valid", false);
      }
    }
  },

  methods: {
    validateTerms() {
      if (!this.terms) {
        this.errorMessage = "You must accept the terms and conditions.";
      } else {
        this.errorMessage = "";
      }
    }
  }
};
</script>
