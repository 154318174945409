<template>
  <v-form v-model="valid" ref="mainForm">
    <v-container>
      <v-row class="mt-0">
        <v-col>
          <v-autocomplete
            dense
            :placeholder="strings.productListText"
            outlined
            :items="
              promotion && promotion.promotionProducts ? sortedProductsAlphabetically(promotion.promotionProducts) : []
            "
            v-model="selectedPromotionProducts"
            label="Add a Product"
            item-value="id"
            :item-text="
              item => {
                return item && item.product ? item.product.productKey : '';
              }
            "
            multiple
            return-object
            attach
            auto
            @input="searchInput = null"
            :search-input.sync="searchInput"
            :menu-props="{ zIndex: '9999' }"
            ref="menu"
          >
            <template v-slot:append-outer>
              <v-slide-x-reverse-transition mode="out-in">
                <v-btn
                  class="primary mt-n2"
                  @click="
                    onAddProduct();
                    closeMenu();
                  "
                >
                  Add
                </v-btn>
              </v-slide-x-reverse-transition>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-col>
        <v-container>
          <v-layout row wrap>
            <v-flex>
              <v-data-table
                :headers="claimProductHeaders"
                :items="
                  localTradeInProducts
                    ? localTradeInProducts.filter(cp => cp.promotionProduct.product.productKey == 'TRADE_IN_PRODUCT')
                    : null
                "
                :item-class="itemBackgroundColor"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:item.promotionProduct.product.productKey="{ item }">
                  <v-row>
                    <v-col :cols="12">
                      <span class="productKeyField">{{ item.promotionProduct.product.productKey }}</span>
                    </v-col>
                    <v-col :cols="12" v-if="askForPurchaseDate || askForPurchasePrice">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Delete Product</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)">
                            mdi-content-duplicate
                          </v-icon>
                        </template>
                        <span>Duplicate Product</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.serialNumber="{ item }">
                  <v-row class="py-2">
                    <v-col :cols="12">
                      <!-- Iterate over rows of fields -->
                      <v-row v-for="(row, rowIndex) in fieldRows" :key="'field-row-' + rowIndex">
                        <!-- Create columns for each field in the row -->
                        <v-col v-for="fieldData in row" :key="'field-' + fieldData.index" cols="12" sm="6">
                          <CustomField
                            v-if="
                              item &&
                                item.promotionProduct &&
                                item.promotionProduct[fieldData.enableFieldKey] &&
                                selectedProgram &&
                                selectedProgram.claimProductCustomFields[fieldData.index - 1]
                            "
                            v-model="item['customFieldValue' + fieldData.index]"
                            :customField="fieldData.field"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:item.actions="{ item }" v-if="!askForPurchaseDate && !askForPurchasePrice">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)">
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete Product</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)">
                        mdi-content-duplicate
                      </v-icon>
                    </template>
                    <span>Duplicate Product</span>
                  </v-tooltip>
                </template>
              </v-data-table>

              <v-dialog v-model="showAddAnotherDialog" persistent max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Add Another Trade-In Item?</v-card-title>
                  <v-card-text>Do you want to add another trade-in item to this invoice?</v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn :loading="isLoading" color="green darken-1" text @click="handleAddAnotherProduct(true)">
                      Yes
                    </v-btn>
                    <v-btn :loading="isLoading" color="red darken-1" text @click="handleAddAnotherProduct(false)">
                      No
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>
import CustomField from "@/gapp-components/components/fields/CustomField";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "InvoiceTradeInProductsForm",
  components: {
    CustomField
  },
  props: {
    askForPurchaseDate: {
      type: Boolean,
      default: false
    },
    askForPurchasePrice: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    allClaimProductHeaders: [
      {
        value: "serialNumber",
        text: "Details",
        align: "left",
        sortable: false
      },
      {
        value: "purchaseData",
        text: "Purchase Data",
        align: "center",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "right",
        sortable: false
      }
    ],
    localTradeInProducts: [],
    searchInput: null,
    selectedPromotionProducts: [],
    localRules: {
      required: [v => !!v || "Field is required"],
      participant: [v => typeof v == "object" || "Participant is required"],
      quantity: [v => !!v || "Quantity is required", v => (!!v && v <= 2147483647) || "Invalid Quantity value"]
    },
    valid: false,
    showAddAnotherDialog: false,
    isLoading: false
  }),
  created() {
    this.$root.$on("InvoiceProductsForm-validate", () => {
      if (this.$refs.mainForm) {
        this.$refs.mainForm.validate();
      }
    });
  },
  watch: {
    localTradeInProducts: {
      deep: true,
      immediate: false,
      handler(v) {
        if (!v) throw new Error("y");
        this.updateTradeInProducts(v);
        if (this.$refs.mainForm) {
          this.$refs.mainForm.validate();
        }
      }
    },
    tradeInProducts: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localTradeInProducts = v;
      }
    },
    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    }
  },
  computed: {
    ...mapGetters([
      "promotion",
      "tradeInProducts",
      "isPublic",
      "objects",
      "strings",
      "flags",
      "rules",
      "selectedProgram"
    ]),
    claimProductHeaders() {
      if (!this.allClaimProductHeaders) return [];

      let claimProductHeadersResult = this.allClaimProductHeaders;

      if (!this.askForPurchaseDate && !this.askForPurchasePrice) {
        claimProductHeadersResult = claimProductHeadersResult.filter(cph => cph.value != "purchaseData");
      } else {
        claimProductHeadersResult = claimProductHeadersResult.filter(cph => cph.value != "actions");
      }

      return claimProductHeadersResult;
    },
    textFieldMask() {
      if (this.strings && this.strings.mask && this.objects && this.objects.tokens) {
        let mask = this.strings.mask;
        let tokens = this.objects.tokens;
        return { mask: mask, tokens: tokens };
      }
      // if no mask, then create a generic mask of 100 (length of serial number field)
      return {
        mask: "*".repeat(100),
        tokens: {
          "*": { pattern: /./ }
        }
      };
    },
    dynamicFields() {
      const fields = [];
      if (!this.selectedProgram || !this.selectedProgram.claimProductCustomFields) {
        return fields;
      }

      this.selectedProgram.claimProductCustomFields.forEach((field, index) => {
        const enableFieldKey = `enableCustomField${index + 1}`;
        fields.push({
          field,
          enableFieldKey,
          index: index + 1
        });
      });

      return fields;
    },
    fieldRows() {
      const rows = [];
      for (let i = 0; i < this.dynamicFields.length; i += 2) {
        rows.push(this.dynamicFields.slice(i, i + 2));
      }
      return rows;
    }
  },
  methods: {
    ...mapActions(["updateTradeInProducts"]),
    setCleanPrice(value, item) {
      if (!value || value == "") return;
      let cleanString = value.replace(/[^0-9.]/g, "");

      item.priceAmount = cleanString;
    },
    itemBackgroundColor(item) {
      if (item.ocrPopulated) {
        return "ocrPopulated";
      }
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    onDeleteProduct(claimProduct) {
      const index = this.localTradeInProducts.indexOf(claimProduct);
      this.localTradeInProducts.splice(index, 1);
    },
    onDuplicateProduct(claimProduct) {
      let claimProductCopy = { ...claimProduct };
      claimProductCopy.serialNumber = "";
      claimProductCopy.ocrPopulated = false;

      this.localTradeInProducts = this.localTradeInProducts.concat(claimProductCopy);
    },
    closeMenu() {
      if (this.$refs && this.$refs.menu) {
        this.$refs.menu.isMenuActive = false;
      }
    },
    onAddProduct(repeat = false) {
      let promotionProducts = [...this.localTradeInProducts];
      this.selectedPromotionProducts.forEach(promotionProduct => {
        promotionProducts.push({
          promotionProduct: promotionProduct
        });
      });
      this.$set(this, "localTradeInProducts", promotionProducts);
      this.$refs.mainForm.validate();
      this.localTradeInProducts = promotionProducts;
      if (repeat) {
        this.selectedPromotionProducts = [];
      } else {
        this.showAddAnotherDialog = true;
      }
    },
    sortedProductsAlphabetically(products) {
      let sorted = products.slice().sort((a, b) => {
        return a.product.productKey.localeCompare(b.product.productKey);
      });
      return sorted.filter(product => product.product.productKey == "TRADE_IN_PRODUCT");
    },
    handleAddAnotherProduct(response) {
      response ? this.repeatAddProduct() : (this.selectedPromotionProducts = []);
      this.showAddAnotherDialog = false;
    },
    repeatAddProduct() {
      this.onAddProduct(true);
    }
  }
};
</script>
