<template>
  <div :value="value" v-if="value" v-bind="$attrs" v-on="$listeners"></div>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "../../../services/api.service";

export default {
  name: "PlaidDialog",
  props: {
    value: { type: String },
    participant: Object,
    existingPlaidAccount: Object,
    language: { type: Object, required: false },
    forExternalAccount: { type: Boolean, default: false },
    isPublic: { type: Boolean, default: false }
  },
  data: () => ({
    loading: true,
    plaidHandler: null,
    plaidScript: null
  }),
  methods: {
    ...mapActions(["updatePlaidAccountId"]),
    addPlaidScript() {
      if (!this.plaidScript) {
        this.plaidScript = document.getElementById("plaid");
        if (!this.plaidScript) {
          this.plaidScript = document.createElement("script");
          this.plaidScript.setAttribute("id", "plaid");
          this.plaidScript.setAttribute("src", "https://cdn.plaid.com/link/v2/stable/link-initialize.js");
          document.head.appendChild(this.plaidScript);
        }
      }
    },
    removePlaidScript() {
      if (this.plaidScript) {
        this.plaidScript = document.getElementById("plaid");
        this.plaidScript.remove();
      }
    },
    updatePlaidLinkToken() {
      this.createPlaidLinkToken(this.existingPlaidAccount);
    },
    createPlaidLinkToken(plaidAccount) {
      let form = {};
      if (plaidAccount && plaidAccount.id) {
        form.plaidAccount = { id: plaidAccount.id };
      }

      let createLinkTokenUrl = this.isPublic
        ? "/api/plaidPrograms/createLinkToken/public"
        : "/api/plaidPrograms/createLinkToken";
      let createPlaidAccountUrl = this.isPublic ? "/api/plaidAccounts/create/public" : "/api/plaidAccounts/create";
      if (this.participant && this.participant.id) {
        createLinkTokenUrl = "/api/plaidPrograms/createLinkToken/" + this.participant.id;
        createPlaidAccountUrl = "/api/plaidAccounts/create/" + this.participant.id;
      }

      form.language = this.language && this.language.name ? this.language.name : "en";

      if (this.forExternalAccount) {
        form.countries = [{ name: "USA" }, { name: "CAN" }];
        createPlaidAccountUrl = "/api/plaidAccounts/externalAccount/create";
      }

      ApiService.post(createLinkTokenUrl, form)
        .then(({ data }) => {
          this.linkToken = data;
          this.plaidHandler = window.Plaid.create({
            token: this.linkToken.link_token,
            onSuccess: (public_token, metadata) => {
              let plaidAccountId = plaidAccount ? plaidAccount.accountId : metadata.account_id;
              ApiService.post(createPlaidAccountUrl, {
                plaidPublicToken: public_token,
                plaidAccountId: plaidAccountId
              })
                .then(({ data }) => {
                  if (this.isPublic) {
                    this.updatePlaidAccountId(data.id);
                  }
                  this.$emit("complete", data);
                })
                .finally(() => {
                  this.overlay = false;
                  this.loading = false;
                });
            },
            onExit: () => {
              this.overlay = false;
            },
            receivedRedirectUri: null
          });
          this.plaidHandler.open();
        })
        .catch(errors => {
          this.errors = ApiService.getErrorsFromResponse(errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortAccounts(accounts) {
      accounts.sort((a, b) => {
        let ret = a.primary ? -1 : b.primary ? 1 : 0;
        if (!a.institutionName) a.institutionName = "";
        if (!b.institutionName) b.institutionName = "";
        if (ret == 0) {
          ret = a.institutionName.toUpperCase().localeCompare(b.institutionName.toUpperCase());
        }
        if (ret == 0) {
          ret = a.name.toUpperCase().localeCompare(b.name.toUpperCase());
        }
        return ret;
      });
    }
  },
  watch: {
    value(v) {
      if (v == "CREATE") {
        this.createPlaidLinkToken();
      } else if (v == "UPDATE") {
        this.updatePlaidLinkToken();
      }
    }
  },
  created() {
    this.addPlaidScript();
  }
};
</script>
