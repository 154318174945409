<template>
  <div v-if="customField && customField.name">
    <v-text-field
      v-if="customField && customField.fieldType.name == 'STRING'"
      v-bind="$attrs"
      v-on="$listeners"
      :label="labelValue"
      v-model="v"
      type="String"
      :class="customField.required ? 'required' : ''"
      maxlength="100"
      :hint="hintValue"
      :rules="validationRules"
      :error-messages="errorMessages"
      @input="handleInput"
    />
    <v-text-field
      v-else-if="customField && customField.name && customField.fieldType.name == 'INTEGER'"
      v-bind="$attrs"
      v-on="$listeners"
      :label="labelValue"
      v-model="v"
      type="Number"
      :class="customField.required ? 'required' : ''"
      :hint="hintValue"
      :rules="validationRules"
      :error-messages="errorMessages"
      @input="handleInput"
    />
    <v-text-field
      v-else-if="customField && customField.name && customField.fieldType.name == 'DECIMAL'"
      v-bind="$attrs"
      v-on="$listeners"
      :label="labelValue"
      v-model="v"
      type="Number"
      :class="customField.required ? 'required' : ''"
      :hint="hintValue"
      :rules="validationRules"
      :error-messages="errorMessages"
      @input="handleInput"
    />
    <template v-else-if="customField.fieldType.name === 'ARRAY'">
      <v-select
        v-if="
          customField.customFieldSelectionType.name === 'SINGLE_SELECT' ||
            customField.customFieldSelectionType.name === 'MULTIPLE_SELECT'
        "
        v-bind="$attrs"
        v-on="$listeners"
        :label="labelValue"
        :class="customField.required ? 'required' : ''"
        v-model="v"
        :items="items"
        :multiple="customField.customFieldSelectionType.name === 'MULTIPLE_SELECT'"
        :hint="hintValue"
        :rules="validationRules"
        :error-messages="errorMessages"
        @input="handleInput"
      >
      </v-select>
    </template>
    <v-switch
      :label="labelValue"
      v-else-if="customField && customField.fieldType.name == 'BOOLEAN'"
      v-bind="$attrs"
      v-on="$listeners"
      true-value="true"
      false-value="false"
      :class="customField.required ? 'required' : ''"
      v-model="v"
      :hint="hintValue"
    />
    <DateTimePickerField
      v-else-if="customField && customField.fieldType.name == 'DATE'"
      v-bind="$attrs"
      v-on="$listeners"
      :label="labelValue"
      :class="customField.required ? 'required' : ''"
      v-model="v"
      defaultTime="00:00"
      :hint="hintValue"
      onlyDate
      format="MM/DD/YYYY"
      returnFormat="YYYY-MM-DD"
      :rules="validationRules"
      :error-messages="errorMessages"
      @input="handleInput"
    ></DateTimePickerField>
    <DateTimePickerField
      v-else-if="customField && customField.fieldType.name == 'DATETIME'"
      v-bind="$attrs"
      v-on="$listeners"
      :label="labelValue"
      :class="customField.required ? 'required' : ''"
      v-model="v"
      defaultTime="00:00"
      :hint="hintValue"
      :rules="validationRules"
      :error-messages="errorMessages"
      @input="handleInput"
    ></DateTimePickerField>
  </div>
</template>

<script>
import ValidationService from "../../services/validation.service";
import DateTimePickerField from "./DateTimePickerField.vue";

export default {
  name: "CustomField",
  props: {
    customField: Object,
    value: [String, Boolean, Array],
    label: String
  },
  components: { DateTimePickerField },
  data: () => ({
    items: [],
    v: "",
    errors: {},
    labelValue: ""
  }),
  mounted() {
    this.initializeValue();
    this.populateItems();
  },
  methods: {
    populateItems() {
      this.items = [];
      if (this.customField && this.customField.fieldValues && this.customField.fieldValues.length > 0) {
        this.items = this.customField.fieldValues.split("|").map(e => e.trim());
      } else {
        this.items = [];
      }
    },
    initializeValue() {
      if (this.value) {
        if (this.customField && this.customField.fieldType.name === "ARRAY") {
          if (this.customField.customFieldSelectionType.name === "MULTIPLE_SELECT" && typeof this.value === "string") {
            this.v = this.value.split("|").map(e => e.trim());
          } else {
            this.v = Array.isArray(this.value) ? this.value : this.value || [];
          }
        } else if (this.customField && this.customField.fieldType.name === "STRING") {
          this.v = this.value || "";
        } else {
          this.v = this.value || null;
        }
      }

      // Initialize label
      this.labelValue = this.label;
      if (this.label == null || this.label == undefined || this.label.trim().length == 0) {
        this.labelValue = this.customField.name;
      }
    },
    handleInput(value) {
      this.$emit("input", value);
      this.$error.clearValidationError(this.errors, this.customField.name);
    }
  },
  watch: {
    value(val) {
      if (this.customField && this.customField.fieldType.name === "STRING") {
        const stringVal = val || "";
        this.$emit("input", stringVal);
      } else {
        this.v = val || "";
      }
    },
    v(val) {
      if (this.customField && this.customField.fieldType.name === "BOOLEAN") {
        if (val !== undefined && val !== null) {
          this.$emit("input", val.toString());
        } else {
          this.$emit("input", val);
        }
      } else if (this.customField && this.customField.fieldType.name === "ARRAY") {
        if (this.customField.customFieldSelectionType === "MULTIPLE_SELECT") {
          if (Array.isArray(val)) {
            this.$emit("input", val.join("|"));
          } else {
            this.$emit("input", "");
          }
        } else {
          this.$emit("input", Array.isArray(val) ? val.join("|") : val || "");
        }
      } else if (this.customField && this.customField.fieldType.name === "STRING") {
        const value = val || "";
        this.$emit("input", value);
      } else {
        this.$emit("input", this.v);
      }
      this.handleInput(val);
    },
    customField: {
      immediate: true,
      deep: true,
      handler() {
        this.populateItems();
      }
    }
  },
  computed: {
    hintValue() {
      return this.customField && this.customField.hint && this.customField.hint != "" ? this.customField.hint : "";
    },
    validationRules() {
      const fieldType = this.customField?.fieldType?.name;
      const rulesByType = {
        STRING: () => [
          ValidationService.generateNotWhitespacesRule(this.customField.name),
          ...ValidationService.generateTextValidationRules(
            this.customField.name,
            this.customField.maxLength,
            this.customField.required
          )
        ],
        ARRAY: () =>
          this.customField.required ? ValidationService.generateDropdownValidationRules(this.customField.name) : [],
        INTEGER: () =>
          ValidationService.generateIntegerValidationRules(this.customField.name, this.customField.required),
        DECIMAL: () =>
          ValidationService.generateDecimalValidationRules(this.customField.name, 2, this.customField.required),
        DATETIME: () => ValidationService.generateDateValidationRules(this.customField.name, this.customField.required),
        DATE: () => ValidationService.generateDateValidationRules(this.customField.name, this.customField.required)
      };

      return rulesByType[fieldType] ? rulesByType[fieldType]() : [];
    },
    errorMessages() {
      return this.errors[this.customField?.name] || [];
    }
  }
};
</script>
