<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title class="text-center">
        {{ $i18n.translate("Product Registration") }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="mainForm">
          <v-container fluid>
            <v-data-table
              :headers="productsToRegisterHeaders"
              :items="localProductsToRegister"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.promotionProduct.product.productKey="{ item }">
                <v-row>
                  <v-col :cols="12">
                    <span class="productKeyField">{{ item.promotionProduct.product.productKey }}</span>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.serialNumber="{ item }">
                <v-row class="py-2">
                  <v-col :cols="12" :xl="6">
                    <v-text-field
                      prefix="S01 -"
                      outlined
                      v-if="item.promotionProduct.enableSerialNumbers"
                      dense
                      :background-color="item.ocrPopulated ? '#bbdefb' : null"
                      :label="$i18n.translate('Serial Number')"
                      v-model="item.serialNumber"
                      @keydown="keyHandler($event)"
                      @input="item.ocrPopulated = false"
                      :class="item.promotionProduct.enableSerialNumbers ? 'required mt-2' : 'mt-2'"
                      :rules="
                        item.promotionProduct.enableSerialNumbers
                          ? [requireSerialNumber(item), checkDuplicatedSerialNumber(item)]
                          : []
                      "
                      :disabled="!item.promotionProduct.enableSerialNumbers"
                      :readonly="!item.promotionProduct.enableSerialNumbers"
                      @blur="formatSerialNumber(item)"
                    >
                      <template v-slot:append>
                        <v-tooltip bottom max-width="500" v-if="flags.includeClaimProductsSerialNumberTooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" @click="() => {}">mdi-information-outline</v-icon>
                          </template>
                          <span>
                            {{ $i18n.translate(strings.claimProductsSerialnumberTooltipText) }}
                          </span>
                        </v-tooltip>
                      </template>
                    </v-text-field>
                    <v-chip v-else class="mt-3" label> {{ $i18n.translate("Serial Number") }} : N/A </v-chip>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.purchaseDate="{ item }">
                <v-row class="py-2">
                  <v-col :cols="12">
                    <DateTimePickerField
                      :label="$i18n.translate('Purchase Date')"
                      :rules="
                        rules.claimProductsPurchaseDate
                          ? rules.claimProductsPurchaseDate
                          : [v => !!v || $i18n.translate('Purchase Date is required')]
                      "
                      :required="true"
                      format="MM/DD/YYYY"
                      defaultTime="00:00"
                      onlyDate
                      v-model="item.purchaseDate"
                    ></DateTimePickerField>
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDeleteProduct(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>{{ $i18n.translate("Delete Product") }} </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2 ml-2" v-bind="attrs" v-on="on" @click="onDuplicateProduct(item)"
                      >mdi-content-duplicate</v-icon
                    >
                  </template>
                  <span> {{ $i18n.translate("Duplicate Product") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-row class="mt-0">
              <v-col>
                <v-autocomplete
                  dense
                  outlined
                  :items="promotion && promotion.promotionProducts ? promotion.promotionProducts : []"
                  v-model="selectedProductsToRegister"
                  :label="$i18n.translate('add a Product')"
                  item-value="id"
                  :item-text="
                    item => {
                      return item && item.product ? item.product.productKey : '';
                    }
                  "
                  multiple
                  return-object
                  :rules="[requireProduct]"
                  class="required mb-5"
                  :hint="$i18n.translate('You must select at least 1 product for this claim')"
                  attach
                  auto
                  @input="searchInput = null"
                  :search-input.sync="searchInput"
                  :menu-props="{ zIndex: '9999' }"
                  ref="menu"
                >
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-btn
                        class="primary mt-n2"
                        @click="
                          onAddProduct();
                          closeMenu();
                        "
                        >Add</v-btn
                      >
                    </v-slide-x-reverse-transition>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <v-col cols="12">
      <div class="text-center">
        <v-btn @click="$emit('previousStep')" text class="mr-2">{{ $i18n.translate("Back") }}</v-btn>
        <v-btn color="primary" @click="$emit('nextStep')" class="mr-2">
          Continue
        </v-btn>
        <v-btn @click="$emit('cancel')" text>{{ $i18n.translate("Cancel") }}</v-btn>
      </div></v-col
    >
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DateTimePickerField from "@/gapp-components/components/fields/DateTimePickerField";

export default {
  name: "ProductRegistrationStep",
  components: { DateTimePickerField },
  computed: {
    ...mapGetters(["promotion", "productsToRegister", "isPublic", "objects", "strings", "flags", "rules"]),
    textFieldMask() {
      if (this.strings && this.strings.mask && this.objects && this.objects.tokens) {
        let mask = this.strings.mask;
        let tokens = this.objects.tokens;
        return { mask: mask, tokens: tokens };
      }
      // if no mask, then create a generic mask of 100 (length of serial number field)
      return {
        mask: "*".repeat(100),
        tokens: {
          "*": { pattern: /./ }
        }
      };
    }
  },
  data: () => ({
    productsToRegisterHeaders: [
      {
        value: "promotionProduct.product.productKey",
        text: this.$i18n.translate("Product Key"),
        align: "left"
      },
      {
        value: "promotionProduct.product.name",
        text: this.$i18n.translate("Product name"),
        align: "left"
      },
      {
        value: "serialNumber",
        text: this.$i18n.translate("Serial Number"),
        align: "left",
        sortable: false
      },
      {
        value: "purchaseDate",
        text: this.$i18n.translate("Purchase Date"),
        align: "left",
        sortable: false
      },
      {
        value: "actions",
        text: "",
        align: "right",
        sortable: false
      }
    ],
    localProductsToRegister: [],
    searchInput: null,
    selectedProductsToRegister: [],
    localRules: {
      required: [v => !!v || this.$i18n.translate("Field is required")],
      participant: [v => typeof v == "object" || this.$i18n.translate("Participant is required")]
    },
    valid: false
  }),
  methods: {
    ...mapActions(["updateProductsToRegister"]),
    setCleanPrice(value, item) {
      if (!value || value == "") return;
      let cleanString = value.replace(/[^0-9.]/g, "");

      item.priceAmount = cleanString;
    },
    keyHandler(v) {
      if (v.key == " " || v.key == ",") {
        event.preventDefault();
      }
    },
    onDeleteProduct(productToRegister) {
      const index = this.localProductsToRegister.indexOf(productToRegister);
      this.localProductsToRegister.splice(index, 1);
    },
    onDuplicateProduct(productToRegister) {
      let productToRegisterCopy = { ...productToRegister };
      productToRegisterCopy.serialNumber = "";
      this.localProductsToRegister = this.localProductsToRegister.concat(productToRegisterCopy);
    },
    onAddProduct() {
      let promotionProducts = this.localProductsToRegister;

      this.selectedProductsToRegister.some(promotionProduct => {
        let found = promotionProducts.findIndex(
          needle => needle.promotionProduct.product.id == promotionProduct.product.id
        );
        if (found == -1) {
          /* if (!this.isPublic) {
            this.$api.get("/api/promotionProducts/" + promotionProduct.id + "/allowPriceEntry").then(res => {
              promotionProduct.allowPriceEntry = res.data;
              promotionProducts = promotionProducts.concat([
                {
                  promotionProduct: promotionProduct
                }
              ]);
              this.selectedProductsToRegister = [];
              this.$set(this, "localProductsToRegister", promotionProducts);
              this.$refs.mainForm.validate();
            });
          } else {*/
          promotionProducts = promotionProducts.concat([
            {
              promotionProduct: promotionProduct
            }
          ]);
          this.selectedProductsToRegister = [];
          this.$set(this, "localProductsToRegister", promotionProducts);
          /*}*/
        } else {
          if (promotionProduct.enableSerialNumbers) {
            promotionProducts = promotionProducts.concat([
              {
                promotionProduct: promotionProduct
              }
            ]);
          }
        }
      });

      this.localProductsToRegister = promotionProducts;
      this.selectedProductsToRegister = [];
    },
    requireProduct() {
      if (this.localProductsToRegister.length > 0) {
        return true;
      } else {
        return this.this.$i18n.translate("At least 1 product is required");
      }
    },
    checkDuplicatedSerialNumber(item) {
      let count = 0;
      this.localProductsToRegister.forEach(element => {
        if (
          element.serialNumber &&
          element.promotionProduct.product.id == item.promotionProduct.product.id &&
          element.serialNumber == item.serialNumber
        ) {
          count++;
        }
      });
      if (count > 1) {
        return this.this.$i18n.translate("Serial number") + " duplicated";
      }
      return true;
    },
    requireSerialNumber(item) {
      if (item.promotionProduct.enableSerialNumbers) {
        if (item.serialNumber) {
          if (item.promotionProduct.product.serialNumberRegex) {
            let regex = RegExp("^" + item.promotionProduct.product.serialNumberRegex);
            if (regex.test(item.serialNumber)) {
              return true;
            } else {
              return "Invalid " + this.this.$i18n.translate("Serial Number");
            }
          } else {
            return true;
          }
        } else {
          return this.this.$i18n.translate("Serial Number") + " required";
        }
      } else {
        return false;
      }
    },
    formatSerialNumber(item) {
      if (item.serialNumber) {
        const parts = item.serialNumber.split("-");
        if (parts.length > 1) {
          const prefix = "S01 -";
          let serialPart = parts[1];
          if (serialPart.length < 7) {
            serialPart = serialPart.padStart(7, "0");
          }
          item.serialNumber = `${prefix}${serialPart}`;
        }
      }
    },
    closeMenu() {
      if (this.$refs && this.$refs.menu) {
        this.$refs.menu.isMenuActive = false;
      }
    }
  },
  watch: {
    localProductsToRegister: {
      deep: true,
      immediate: false,
      handler(v) {
        if (!v) throw new Error("y");
        this.updateProductsToRegister(v);
        if (this.$refs.mainForm) {
          this.$refs.mainForm.validate();
        }
      }
    },
    productsToRegister: {
      deep: true,
      immediate: true,
      handler(v) {
        this.localProductsToRegister = v;
      }
    },
    valid: {
      immediate: true,
      handler(v) {
        this.$emit("validation", v);
      }
    }
  },
  created() {
    this.$root.$on("InvoiceProductsForm-validate", () => {
      if (this.$refs.mainForm) {
        this.$refs.mainForm.validate();
      }
    });
  }
};
</script>
